import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';
import React from 'react'
import { useSelector } from 'react-redux';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import { HierarchyType } from '../../helpers/enums/HierarchyType';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import ICurrentUser from '../../interfaces/ICurrentUser';
import { IDownloadDocumentsDto } from '../../interfaces/IDownloadDocumentsDto';
import DataReadService from '../../services/DataReadService';
import { Utils } from '../../utilities/utils';
import { DateTime } from "luxon";
import _ from 'lodash';
import DataWriteService from '../../services/DataWriteService';
import { IUpdateDocumentAccessDto } from '../../interfaces/IUpdateDocumentPermissionsDto';
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';
import { getSelectedHierarchyTypeCount } from '../../helpers/ContentHierarchyHelpers';

export type BulkDocumentActionOption = 'delete' | 'download' | 'restrict' | null;

export interface IBulkDocumentActionHelper {
    value: BulkDocumentActionOption,
    selected: IContentHierarchy[],
    popperAnchorEl: HTMLButtonElement | null,
    submitting: boolean,
    isActive: boolean,
    isDeleteMode: boolean,
    isDownloadMode: boolean,
    isRestrictionMode: boolean,
    hasSelections: Function,
    setInactive: Function,
    setSubmitting: Function,
    setSelectedForBulkAction: Function,
    onToggleBulkAction: (evt: React.MouseEvent<HTMLButtonElement>, mode?: BulkDocumentActionOption) => void,
    isFolderSelectable: (hierarchy: IContentHierarchy) => boolean;
    isRowSelectable: (hierarchy: IContentHierarchy) => boolean;
    execute: (...args: any[]) => Promise<void>
}

function useDocumentsBulkActionHelper() {
    const [selectedForBulkAction, setSelectedForBulkAction] = React.useState<IContentHierarchy[]>([]);
    const [bulkActionMode, setBulkActionMode] = React.useState<BulkDocumentActionOption>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [submitting, setSubmitting] = React.useState(false);

    const isActive = bulkActionMode != null;
    const isDeleteMode = bulkActionMode === 'delete';
    const isDownloadMode = bulkActionMode === 'download';
    const isRestrictionMode = bulkActionMode === 'restrict';

    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);
    const { enqueueSnackbar } = useSnackbar();

    const setAndValidateSelections = (hierarchies: IContentHierarchy[]) => {
        setSelectedForBulkAction(getValidatedHierarchies(hierarchies));
    }

    const getValidatedHierarchies = (hierarchies: IContentHierarchy[]) => {
        const validatedHierarchies: IContentHierarchy[] = [];

        hierarchies.forEach(hierarchy => {
            isRowSelectable(hierarchy) && validatedHierarchies.push(hierarchy);
        });

        return validatedHierarchies;
    }

    const getSelectedFileCount = () => {
        return getSelectedHierarchyTypeCount(HierarchyType.File.id, selectedForBulkAction);
    }

    const getSelectedCustomFolderCount = () => {
        return getSelectedHierarchyTypeCount(HierarchyType.CustomFolder.id, selectedForBulkAction);
    }

    const fileMessageString = getSelectedFileCount() === 1 ? 'file' : 'files';
    const folderMessageString = getSelectedCustomFolderCount() === 1 ? 'folder' : 'folders'; 

    const getExecute = React.useCallback(() => {
        if (isDownloadMode) {
            return handleBulkDownload;
        }
        if (isDeleteMode) {
            return handleBulkDelete
        }
        if (isRestrictionMode) {
            return handleBulkFileRestrictionUpdate;
        }

        return async () => {};
    },[bulkActionMode, selectedForBulkAction]);

    const setInactive = () => {
        setBulkActionMode(null);
        setSelectedForBulkAction([]);
        setAnchorEl(null);
        setSubmitting(false);
    }

    const isFolderSelectable = (hierarchy: IContentHierarchy) => {
        if (isDeleteMode && currentUser.isMossAdamsStaff) {
            return [HierarchyType.CustomFolder.string,
                HierarchyType.RestrictedFolder.string,
            ].includes(hierarchy.hierarchyTypeDescription);
        }
        if (isDownloadMode) {
            return [
                HierarchyType.Engagement.string,
                HierarchyType.CustomFolder.string,
                HierarchyType.SystemFolder.string,
                HierarchyType.SecureFolder.string,
                HierarchyType.RestrictedFolder.string,
            ].includes(hierarchy.hierarchyTypeDescription);
        }
        return false;
    }

    const isRowSelectable = (hierarchy: IContentHierarchy) => {
        const type = hierarchy.hierarchyTypeDescription;
        let selectable = false;

        const deleteSelectableTypes = [
            HierarchyType.File.string,
            ...(currentUser.isMossAdamsStaff ? [HierarchyType.CustomFolder.string, HierarchyType.RestrictedFolder.string] : [])
        ];

        const downloadSelectableTypes = [
            HierarchyType.File.string,
            HierarchyType.CustomFolder.string, 
            HierarchyType.Engagement.string,
            HierarchyType.SystemFolder.string,
            HierarchyType.SecureFolder.string,
            HierarchyType.RestrictedFolder.string,
        ]

        if (isDeleteMode) {
            selectable = deleteSelectableTypes.includes(type);
        }
        else if (isDownloadMode) {
            selectable = downloadSelectableTypes.includes(type);
        }
        else if (isRestrictionMode) {
            selectable = type === HierarchyType.File.string 
                && !hierarchy.parentIsRestricted 
                && (currentUser.isMossAdamsStaff || hierarchy.createdByUserId === currentUser.userId);

            if (selectedForBulkAction.length) {
                selectable = selectable
                && selectedForBulkAction[0].createdByUserId === hierarchy.createdByUserId
                && selectedForBulkAction[0].engagementId === hierarchy.engagementId 
                && selectedForBulkAction[0].clientId === hierarchy.clientId;
            }
        }

        return selectable;
    }

    const handleBulkDownload = async () => {
        setSubmitting(true);

        const request: IDownloadDocumentsDto[] = _.cloneDeep(selectedForBulkAction).filter(hierarchy => hierarchy.hierarchyTypeId === HierarchyType.File.id)
            .map(hierarchy => {
                return {
                    id: hierarchy.id!,
                    fileName: hierarchy.name!,
                    fileExtension: hierarchy.fileExtension!,
                    folderId: hierarchy.parentId!,
                    clientId: hierarchy.clientId,
                }
            });

        const dataReadService = new DataReadService();
        const response = await dataReadService.DownloadDocuments(request);
        if (response.status) {
            //@ts-ignore createObjectURL expects Blob or MediaSource object.
            //The api returns a BlobPart(superset that contains Blob) but IPlatformApiGetResult casts it to a BlobPart[]
            let url = window.URL.createObjectURL(response.data as Blob);
            saveAs(url, `MA_Portal_Files_${DateTime.now().toFormat('yyyy_MM_dd')}.zip`);
            const downloadFilesSuccess = `${getSelectedFileCount()} selected ${fileMessageString} downloaded successfully.`;
            enqueueSnackbar(downloadFilesSuccess, { variant: SnackbarVariantTypes.Success });
            setInactive();
        }
        else {
            if (response.timeoutOccured) {
                response.errorMessages = ["The download failed due to time out. Please select fewer files and try again."];
            }
            Utils.enqueueMultiLineSnackbar([...response.errorMessages], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            setSubmitting(false);
        }
    }

    const handleBulkFileRestrictionUpdate = async (onUpdateAccess: Function, users: string[], restricted: boolean) => {
        setSubmitting(true);
        const documentIds = selectedForBulkAction.map(s => s.id);

        const dataWriteService = new DataWriteService();
        const promises: Promise<IPlatformApiPostResult>[] = [];
        documentIds.forEach(id => {
            const dto: IUpdateDocumentAccessDto = {
                documentId: id,
                userIds: users,
                restricted: restricted
            }
            promises.push(dataWriteService.UpdateDocumentAccessPermissions(dto));
        })
        const responses = await Promise.all(promises);

        const errors = responses.filter(response => !response.status);
        if (!errors.length) {
            const updateRestrictionsSuccess = `File restrictions for ${getSelectedFileCount()} selected ${fileMessageString} updated successfully.`;
            enqueueSnackbar(updateRestrictionsSuccess, { variant: SnackbarVariantTypes.Success });
        }
        else {
            Utils.enqueueMultiLineSnackbar([...errors[0].errorMessages], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }

        onUpdateAccess(restricted, users);
        setInactive();
    }

    const handleBulkDelete = async (onSuccess: Function) => {
        setSubmitting(true);

        const dataWriteService = new DataWriteService();

        const request = _.cloneDeep(selectedForBulkAction).map(hierarchy => {
            return {
                id: hierarchy.id,
                hierarchyTypeId: hierarchy.hierarchyTypeId,
            }
        });

        const result = await dataWriteService.DeleteFoldersOrDocuments(request);
        if (result.status) {
            const deleteSuccess = `${getSelectedFileCount()} ${fileMessageString} and ${getSelectedCustomFolderCount()} ${folderMessageString} deleted successfully`;
            onSuccess();
            setInactive();
            enqueueSnackbar(deleteSuccess, { variant: SnackbarVariantTypes.Success });
        } else {
            Utils.enqueueMultiLineSnackbar([...result.errorMessages], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            setSubmitting(false);
        }
    }

    const onToggleBulkAction = (event: React.MouseEvent<HTMLButtonElement>, mode: BulkDocumentActionOption = null) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        if (isActive) {
            setInactive();
            return;
        }
        setBulkActionMode(mode);
    }

    const helper: IBulkDocumentActionHelper = {
        value: bulkActionMode,
        execute: getExecute(), 
        selected: selectedForBulkAction,
        setSelectedForBulkAction: setAndValidateSelections,
        isActive,
        setInactive,
        isDeleteMode,
        isDownloadMode,
        isRestrictionMode,
        hasSelections: () => selectedForBulkAction.length !== 0,
        submitting,
        setSubmitting: (submitting: boolean) => setSubmitting(submitting),
        popperAnchorEl: anchorEl,
        onToggleBulkAction,
        isFolderSelectable,
        isRowSelectable,
    }

    return helper;
}

export default useDocumentsBulkActionHelper