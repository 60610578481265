import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControl, Grid, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setClientEngagementSelectorDisabled, setEngagementManagementIsDirty } from "../../store/actions";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import { GridApi, AllModules, GridReadyEvent, ColumnApi, RowNode, GroupCellRendererParams, IsGroupOpenByDefaultParams, SetFilterValuesFuncParams, RowSelectedEvent, RowClickedEvent, Column, RowDataUpdatedEvent } from "@ag-grid-enterprise/all-modules";
import DataReadService from "../../services/DataReadService";
import ITaskListRequestDto from "../../interfaces/ITaskListRequestDto";
import IPlatformApiGetResult from "../../interfaces/IPlatformApiGetResult";
import IEngagementManagementListVM from "../../interfaces/IEngagementManagementListVM";
import ICurrentUser from "../../interfaces/ICurrentUser";
import { useHistory } from "react-router-dom";
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import { TaskStatus } from "../../helpers/enums/TaskStatus";
import { useSnackbar } from "notistack";
import IUser from "../../interfaces/IUser";
import ITask from "../../interfaces/ITask";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import EngagementManagementDetails from "./EngagementManagementDetails";
import PageHeader from "../PageHeader/PageHeader";
import { agGridThemeOverride, boxShadow } from "../../helpers/portalTheme";
import { Utils } from "../../utilities/utils";
import RouteLeavingGuard from "../ConfirmDialog/RouteLeavingGuard";
import { errorRequestNotFoundText, errorSeverity } from "../documentManagement/DocumentManagement";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import { CellClassParams, CheckboxSelectionCallbackParams, RowClassParams, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import { TaskPriority } from "../../helpers/enums/TaskPriority";
import { DateTime } from "luxon";
import XLSX from 'xlsx';
import requestedDateRenderer from '../../helpers/RequestedDateRenderer';
import IUserEngagement from "../../interfaces/IUserEngagement";
import { LineOfBusinessId } from "../../helpers/enums/LineOfBusinessId";
import { engagementManagementPlaceholderNodeTitle, nullTaskEntityGroupName, someTaskHasEntityValue, someTaskHasEntityValueAnd, taskIsNotPlaceholderNode, taskIsPlaceholderNode, taskStatusIsDeleted, taskStatusIsDraft } from "../../helpers/TaskHelpers";
import { entityColumnComparator, initialEngagementRequestsGroupOrderComparatorem } from "../../helpers/Comparators";
import { TabPanel } from "../TabPanel/TabPanel";
import IClientEngagementManagementData from './IClientEngagementManagementData';
import { ClientTabBar } from "./ClientTabBar";
import EngagementGroupCellRenderer from "./EngagementGroupCellRenderer";
import TaskAssigneeCellRenderer from "../AgGridRenderers/TaskAssigneeCellRenderer";
import { attachmentsRenderer, dateRenderer, entityCellRenderer, restrictedRenderer } from "./RequestCellRenderers";
import { assignedToUserNameValueFormatter, mossAdamsContactUserNameValueFormatter } from "./RequestCellValueFormatters";
import DownloadTaskDocumentsButton from "../DownloadTaskDocumentsButton/DownloadTaskDocumentsButton";
import { RequestDescriptionTooltip, RequestNotesTooltip } from "./EngagementManagementTooltips";
import ITaskCommentForExportVM from "../../interfaces/ITaskCommentForExportVM";
import { lookupValue, userListValue } from "../../helpers/agGridHelpers";
import { useLookupLists } from "../../hooks/useLookupLists";
import ConditionalVisiblity from "../common/ConditionalVisibility/ConditionalVisiblity";
import { FailedToLoadEngagementManagementText } from "../../helpers/Constants";
import { SavedViewKeys } from "../../helpers/SavedViewKeys";
import LayoutManagerModalButton from "../SavedViewModal/LayoutManagerModalButton";
import { assignedToUserNameValueGetter } from "../AgGridValueGetters/TaskAssigneeValueGetter";
import ITaskAssignee from "../../interfaces/ITaskAssignee";
import RestrictedIcon from "../common/RestrictedIcon/RestrictedIcon";

const errorGettingTaskList = "There was a problem fetching the request list, please refresh the page and try again.";
const errorGettingComments = "Failed to get comments for export.";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "nowrap",
        flex: 1,
    },
    gridRoot: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        padding: theme.spacing(4),
        ...agGridThemeOverride,
        "& .MuiInputBase-root": {
            minWidth: theme.spacing(50),
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "inherit",
        },
        "& input:disabled, .Mui-disabled, .Mui-disabled span": {
            color: theme.palette.mossadams.gray700,
        },
        "& .ag-row.ag-row-level-0.ag-row-group-expanded": {
            borderBottom: `solid 0.125rem ${theme.palette.mossadams.divider}`
        }
    },
    pageHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    detailsPane: {
        ...boxShadow,
        maxWidth: "480px",
        marginLeft: theme.spacing(5),
        "&&.MuiGrid-item": {
            padding: 0
        }
    },
    agTaskGrid: {
        width: "100%",
        height: "100%",
    },
    fullHeight: {
        height: "calc(100vh - 100px)",
        "&&.MuiGrid-item": {
            padding: 0
        }
    },
    divider: {
        height: theme.spacing(7.5),
    },
    topIcons: {
        marginTop: theme.spacing(8),
    },
    topControls: {
        display: "flex",
        alignItems: "center",
        paddingBottom: theme.spacing(12),
        justifyContent: "flex-end",
    },
    resetButton: {
        marginRight: theme.spacing(5),
        minWidth: theme.spacing(20),
    },
    completedFiltered: {
        opacity: "0.3",
    },
    gridPane: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "row",
        flexGrow: 1
    },
    detailTop: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    whiteText: {
        color: theme.palette.primary.contrastText,
    },
    draft: {
        ...agGridThemeOverride.draft
    },
    complete: {
        ...agGridThemeOverride.complete
    },
    deleted: {
        ...agGridThemeOverride.deleted
    },
    highPriority: {
        ...agGridThemeOverride.highPriority
    },
    overdueIndicator: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        backgroundColor: "red",
        borderRadius: "50%",
        display: "inline-block",
        marginRight: theme.spacing(2.5),
    },
    overdueDate: {
        color: theme.palette.error.main
    },
    disabledUser: {
        color: theme.palette.mossadams.gray200,
    },
    counter: {
        height: theme.spacing(10),
        width: theme.spacing(10),
        borderRadius: "50%",
        display: "inline-block",
        color: "white",
        textAlign: "center",
        marginRight: theme.spacing(5),
    },
    completedCounter: {
        backgroundColor: "green",
    },
    overdueCounter: {
        backgroundColor: "red",
    },
    fullWidth: {
        width: "100%",
    },
    noEngagementLabel: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(10),
        paddingBottom: theme.spacing(4),
    },
    paddingRight: {
        paddingRight: theme.spacing(10)
    },
    body: {
        filter: `drop-shadow(${boxShadow.boxShadow})`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    boxShadow,
    autoColumnHeader: {
        display: "none",
    },
    excelSvgIcon: {
        maskImage: 'url(/images/excelIcon.svg)',
        transform: 'scale(0.85)'
    },
    unfilterIcon: {
        maskImage: 'url(/images/UnfilterIcon.svg)',
        transform: 'scale(1.25)'
    },
    tabPanel: {
        height: '100%'
    },
    container: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    svgIconButton: {
        // The span for the SVG image is nested in the span tag for the label so that 
        // is why it is necessary to use two child combinator to apply the style
        '& span > span': {

            backgroundColor: "#fff",
            display: 'flex',
            height: '24px',
            '&:hover': {
                backgroundColor: "#fff",
                cursor: 'pointer'
            },
            maskSize: 'contain',
            width: '24px'
        }
    }
}));

interface IBulkEngagementRequestHelper {
    value: BulkEngagementRequestActionOption,
    selected: ITask[],
    isActive: boolean,
    setDownloadMode: Function,
    setInactive: Function,
}

type BulkEngagementRequestActionOption = 'download' | null;

export interface IEngagementManagementProps {
    routeId?: string;
    selectedClients?: string[];
    selectedEngagements?: string[];
    areSelectedClientEngagementsLoaded?: boolean;
    currentUser?: ICurrentUser;
    isDirty: boolean;
}

const EngagementManagement = (props: IEngagementManagementProps): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [rawData, setRawData] = React.useState<ITask[]>([]);
    const [groupedData, setGroupedData] = React.useState<IClientEngagementManagementData[]>([]);
    const [showDetails, setShowDetails] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState<ITask | null>();
    const [selectedGridApi, setSelectedGridApi] = React.useState<GridApi>();
    const [gridApi, setGridApi] = React.useState<GridApi[]>([]);
    const [columnApi, setColumnApi] = React.useState<ColumnApi[]>([]);
    const [searchField, setSearchField] = React.useState("");
    const [clientUserList, setClientUserList] = React.useState<IUser[] | null>(null);
    const [mossAdamsUserList, setMossAdamsUserList] = React.useState<IUser[]>([]);
    const [userEngagementList, setUserEngagementList] = React.useState<IUserEngagement[]>([]);
    const [uploadFolderId, setUploadFolderId] = React.useState("");
    const [cancelConfirmDialogIsOpen, setCancelConfirmDialogIsOpen] = React.useState<boolean>(false);
    const [rowClickedTaskId, setRowClickedTaskId] = React.useState<string | null>(null);
    const [unableToGetUrlTask, setUnableToGetUrlTask] = React.useState<boolean>(false);
    const [completedFiltered, setCompletedFiltered] = React.useState<boolean>(false);
    const [clientToUseEntityGroupMap, setClientToUseEntityGroupMap] = React.useState<Map<string, boolean>>(new Map<string, boolean>());
    const [clientToGridApiMap, setClientToGridApiMap] = React.useState<Map<string, GridApi>>(new Map<string, GridApi>());
    const [clientToColumnApiMap, setClientToColumnApiMap] = React.useState<Map<string, ColumnApi>>(new Map<string, ColumnApi>());
    const [previouslySelectedClient, setPreviouslySelectedClient] = React.useState<string>('');
    const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);
    const [expandAll, setExpandAll] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState(false)

    const [bulkActionMode, setBulkActionMode] = React.useState<BulkEngagementRequestActionOption>(null);
    const [selectedForBulkAction, setSelectedForBulkAction] = React.useState<ITask[]>([]);

    const dispatch = useDispatch();
    const setIsDirty = (isDirty: boolean) => {
        dispatch(setEngagementManagementIsDirty({ engagementManagementIsDirty: isDirty }));
    }

    const getSelectedTabGridApi = () => {
        return clientToGridApiMap.get(groupedData?.[selectedTabIndex]?.client);
    }

    const getSelectedTabColumnApi = () => {
        return clientToColumnApiMap.get(groupedData?.[selectedTabIndex]?.client);
    }

    const { lookupLists, loadingLookups, lookupListError } = useLookupLists();

    let {
        areas = [],
        phases = [],
        priorities = [],
        statuses = [],
        socStatuses = [],
        taskTypes = [],
    } = lookupLists || {};

    const bulkActionHelper = React.useMemo((): IBulkEngagementRequestHelper => {
        return {
            value: bulkActionMode,
            selected: selectedForBulkAction,
            isActive: bulkActionMode != null,
            setDownloadMode: () => setBulkActionMode('download'),
            setInactive: () => {
                setBulkActionMode(null);
                setSelectedForBulkAction([])
            },
        }
    }, [bulkActionMode, setBulkActionMode, selectedForBulkAction]);

    React.useEffect(() => {
        const api = getSelectedTabGridApi();
        if (api) {
            api.refreshCells();
            if (bulkActionMode !== null) {
                handleCloseDetails();
            }
        }
    }, [bulkActionMode])

    React.useEffect(() => {
        if (groupedData.length) {
            updateExpandAllStatusForCurrentTab();
        }
    }, [selectedTabIndex, clientToGridApiMap, groupedData])

    function selectTask(taskId: string, task: ITask) {
        const allAgGridsHaveLoaded = gridApi.length === props?.selectedClients?.length;

        const gridApiForTask = gridApi.find(_gridApi => {
            _gridApi.refreshCells({ force: true });
            return _gridApi.getRowNode(taskId);
        });

        if (allAgGridsHaveLoaded && gridApiForTask) {
            let taskRowNode: (RowNode | undefined);
            taskRowNode = gridApiForTask.getRowNode(taskId);

            if (taskRowNode?.parent) {
                let parent: RowNode | null = taskRowNode?.parent;
                while (parent) {
                    gridApiForTask.setRowNodeExpanded(parent, true);
                    parent = parent?.parent;
                }
                taskRowNode.setSelected(true);
                gridApiForTask.ensureNodeVisible(taskRowNode);
            }
        }
        // select tab for for direct link
        if (task.clientId && !previouslySelectedClient && clientToGridApiMap.get(task.clientId)) {
            const index = props.selectedClients!.findIndex(clientId => clientId === task.clientId);
            setSelectedTabIndex(index === -1 ? 0 : index);
        }
        handleGridFilterChanged();
    }

    React.useEffect(() => {
        const taskId = Utils.getUrlGUID();

        if (unableToGetUrlTask) {
            return;
        }

        if (taskId && props.areSelectedClientEngagementsLoaded && rawData.length) {
            const taskToSelect = rawData.find((task) => task.taskId === taskId);
            if (taskToSelect) {
                setTimeout(() => { selectTask(taskId, taskToSelect) }, 500);
            } 
        } 
        else {
            if (props.routeId && !Utils.isGUID(props.routeId)) {
                setUnableToGetUrlTask(true);
                enqueueSnackbar(errorRequestNotFoundText, { variant: errorSeverity, preventDuplicate: true });
            }
        }
    }, [rawData, gridApi, clientToGridApiMap, props.areSelectedClientEngagementsLoaded]);

    const removeNoTasksAvailableNodes = React.useCallback(() => {
        gridApi.forEach(api => {
            const nodesToRemove: any[] = []
            api.forEachNode(node => {
                if (taskIsPlaceholderNode(node?.data)) {
                    nodesToRemove.push(node.data);
                }
            })
            api.applyTransaction({ remove: nodesToRemove });
        })
    }, [gridApi])

    React.useEffect(() => {
        displayDetails(false);
        bulkActionHelper.setInactive();
        setSearchField("");
        setClientToUseEntityGroupMap(new Map<string, boolean>());
        groupedData && setPreviouslySelectedClient(groupedData?.[selectedTabIndex]?.client || '');

        if (props?.selectedClients?.length) {
            dispatch(setClientEngagementSelectorDisabled(true));

            const dataReadService = new DataReadService();

            const requestDto: ITaskListRequestDto = {
                clientIds: props.selectedClients || [],
                engagementIds: props.selectedEngagements || []
            };
            removeNoTasksAvailableNodes();
            setIsLoading(true);

            dataReadService.GetEngagementManagementList(requestDto).then((response: IPlatformApiGetResult<IEngagementManagementListVM>) => {
                if (response.status) {
                    const vm: IEngagementManagementListVM = response.data[0];
                    _.forEach(vm.tasks, (value: ITask, key: number) => {

                        if (value.title === "") {
                            vm.tasks[key].title = engagementManagementPlaceholderNodeTitle;
                            vm.tasks[key].taskNumber = "";
                            vm.tasks[key].taskStatusDescription = "";
                            vm.tasks[key].taskId = `noTasks-${key}`;
                        }
                    });

                    setRawData(vm.tasks);
                    setClientUserList(vm.clientUserList);
                    setMossAdamsUserList(vm.mossAdamsUserList);
                    setUserEngagementList(vm.userEngagementList);
                }
                else {
                    enqueueSnackbar(errorGettingTaskList, { variant: SnackbarVariantTypes.Error, preventDuplicate: true, autoHideDuration: null });
                    setRawData([]);
                    setClientUserList(null);
                    setMossAdamsUserList([]);
                    setUserEngagementList([]);
                }
                setIsLoading(false);
            })
                .finally(() => {
                    dispatch(setClientEngagementSelectorDisabled(false));
                });
        } else {
            setRawData([]);
            setClientUserList(null);
            setMossAdamsUserList([]);
        }
    }, [props.selectedClients, props.selectedEngagements]);

    React.useEffect(() => {
        if (selectedRow && selectedRow.taskId) {
            let folderId = selectedRow.taskListClientDocumentsFolderId;

            setUploadFolderId(folderId || "");

            history.replace('/engagement-management/' + selectedRow.taskId);
        }
    }, [selectedRow, selectedGridApi]);

    React.useEffect(() => {
        var groupedData = transformData(rawData);
        getShowEntityFieldStatusForClientGrids(groupedData);
        setGroupedData(groupedData);
        getSelectedTabGridApi()?.redrawRows();
        transformSelectedTabIndex();
    }, [rawData]);

    React.useEffect(() => {
        if (gridApi?.length === 1) {
            gridApi[0].ensureNodeVisible(selectedRow, 'middle');
        }
    }, [gridApi, groupedData])

    React.useEffect(() => {
        gridApi.forEach((api: GridApi) => {
            api.setQuickFilter(searchField);
        });
    }, [searchField]);

    function getShowEntityFieldStatusForClientGrids(newGroupedData: IClientEngagementManagementData[]) {
        const map = new Map<string, boolean>();
        newGroupedData.forEach(data => {
            map.set(data.client, someTaskHasEntityValue(data.value));
        });
        setClientToUseEntityGroupMap(map);
    }

    function transformData(taskArray: any) {
        var preppedData: IClientEngagementManagementData[] = [];

        var temp = _.groupBy(taskArray, (item: ITask) => item.clientName);
        _.forEach(temp, (value: ITask[], key: string) => {
            let obj: IClientEngagementManagementData = {
                key: key,
                value: value,
                client: value[0]?.clientId || '',
                completed: value.filter((item: ITask) => { return item.taskStatusDescription == TaskStatus.Completed.string; }).length,
                overdue: value.filter((item: ITask) => {
                    const todayDateOnly = new Date().setHours(0, 0, 0, 0);
                    const requestDateOnly = item.requestedDate ? item.requestedDate.setHours(0, 0, 0, 0) : null;
                    const isOverdue = requestDateOnly ? requestDateOnly < todayDateOnly : false;
                    return isOverdue;
                }).length,
                engagementCount: value.filter((item: ITask) => { return item.engagementId !== "00000000-0000-0000-0000-000000000000" }).length,
                showAreaColumn: value.filter((item: ITask) => { return item.engagementLineOfBusinessId == LineOfBusinessId.BA }).length > 0,
                showSocStatusColumn: value.filter((item: ITask) => { return item.isSocEngagementType == true }).length > 0,
            };
            preppedData.push(obj);
        });

        preppedData.sort((a, b) =>
            props.selectedClients?.findIndex(clientId => clientId === a.client)! - props.selectedClients?.findIndex(clientId => clientId === b.client)!
        )

        return preppedData;
    }

    const transformSelectedTabIndex = () => {
        if (previouslySelectedClient) {
            const index = props.selectedClients!.findIndex(clientId => clientId === previouslySelectedClient);
            setSelectedTabIndex(index === -1 ? 0 : index);
        }
    }

    function getNumberOfEngagementGroups(api: GridApi) {
        let count = 0;
        api.forEachNode((node: RowNode) => {
            if (node.group && node.field === 'engagementName') {
                count++;
            }
        });
        return count;
    }

    function onGridReady(event: GridReadyEvent, index: number) {
        gridApi.push(event.api);
        columnApi.push(event.columnApi);
        const client = groupedData[index].client;
        clientToGridApiMap.set(client, event.api);
        clientToColumnApiMap.set(client, event.columnApi);

        setGridApi([...gridApi]);
        setColumnApi(columnApi);
        setClientToGridApiMap(clientToGridApiMap)
        setClientToColumnApiMap(clientToColumnApiMap);

        if (getNumberOfEngagementGroups(event.api) == 1) {
            event.api.expandAll();
        }
        updateExpandAllStatusForCurrentTab();
    }

    function handleGridFilterChanged() {
        var newCompletedFiltered = true;
        gridApi.forEach((api: GridApi) => {
            const statusFilter: any = api.getFilterInstance("taskStatusDescription");
            if (statusFilter) {
                var selectedValues = statusFilter.appliedModelValues;
                if (selectedValues == null || selectedValues["Completed"] == true) {
                    newCompletedFiltered = false;
                }
            }
        });
        setCompletedFiltered(newCompletedFiltered);
    }


    function toggleCompletedFilter() {
        var gridId = "grid#";
        var counter = 0;
        gridApi.forEach((api: GridApi) => {
            const statusFilter: any = api.getFilterInstance("taskStatusDescription");
            if (statusFilter) {
                var defaultValues = statusFilter.getValues();
                if (!completedFiltered) {
                    defaultValues = defaultValues.filter((v: string) => {
                        if (v !== 'Completed') { // Deselect these
                            return true;
                        } else {
                            return false;
                        }
                    });
                }
                else {
                    defaultValues = defaultValues.filter(() => {
                        return true; //Select all
                    });
                }

                setCompletedFiltered(!completedFiltered);
                statusFilter.setModel({ values: defaultValues });
                api.onFilterChanged();
            }

            counter++;
        });

    }

    function resetFilters() {
        const api = getSelectedTabGridApi();
        const columnApi = getSelectedTabColumnApi();

        if (api && columnApi) {
            api.setFilterModel(null);
            initializeFilterDefault(api);
            initializeSortDefault(columnApi);
        }
    }

    function resetColumns() {
        const api = getSelectedTabGridApi();
        const columnApi = getSelectedTabColumnApi();

        if (api && columnApi) {
            columnApi.resetColumnState();
            columnApi.resetColumnGroupState();
            initializeColumnDefault(api, columnApi);
            initializeSortDefault(columnApi);
            api.sizeColumnsToFit();
        }
    }

    function initializeColumnDefault(api: GridApi, columnApi: ColumnApi) {
        var columnsToShow = [];
        var columnsToHide = [];

        const taskData: ITask[] = [];
        api.forEachNode(rowNode => rowNode?.data?.taskId && taskData.push(rowNode?.data));
        if (taskData.length) {
            taskData.every(task => task?.taskEntity === null) && columnsToHide.push("taskEntity")
        }

        // iterate over all columns (visible or not) and work out
        // now many columns can fit (based on their minWidth)
        var totalColsWidth = 0;
        var allColumns = columnApi.getAllColumns()!;

        if (allColumns) {
            for (var i = 0; i < allColumns.length; i++) {
                var column = allColumns[i];
                totalColsWidth += column.getMinWidth() ?? 0;
                var colId = column?.getColId();
                if (colId) {
                    // Omit the "engagementName" column because this will unnecessarily duplicate the header information
                    if (colId === "engagementName" || colId === "createdByUserName" || colId === "hasAttachments" || colId === "updatedDateTime") {
                        columnsToHide.push(colId);
                    } else {
                        columnsToShow.push(colId);
                    }
                }
            }
        }

        // show/hide columns based on current grid width
        columnApi.setColumnsVisible(columnsToShow, true);
        columnApi.setColumnsVisible(columnsToHide, false);
    }

    function initializeFilterDefault(api: GridApi) {
        const statusFilter: any = api.getFilterInstance("taskStatusDescription");
        statusFilter.refreshFilterValues();

        if (statusFilter) {
            const allFilterValues = statusFilter.getValues();
            setCompletedFiltered(true);
            statusFilter.setModel({ values: allFilterValues });
            api.onFilterChanged();
        }
    }

    function initializeSortDefault(columnApi: ColumnApi) {
        columnApi.applyColumnState({
            state: [{ colId: 'taskNumber', sort: 'asc' }],
            defaultState: { sort: null },
        });
    }

    const handleCloseDetails = () => {
        if (props.isDirty) {
            setCancelConfirmDialogIsOpen(true);
        } else {
            setShowDetails(false);
            selectedGridApi?.deselectAll();
        }
    }

    function displayDetails(show: boolean) {
        setShowDetails(show);
        if (show == false) {
            selectedGridApi?.deselectAll();
        }
    }

    const handleGridRowClicked = (e: RowClickedEvent) => {
        if (bulkActionHelper.isActive) {
            return;
        }

        if (e.data && e.data.taskId != selectedRow?.taskId) {
            if (props.isDirty) {
                setRowClickedTaskId(e.data.taskId)
                setCancelConfirmDialogIsOpen(true);
            }
        }
    }

    function handleRowSelected(event: RowSelectedEvent) {
        const selectedRows = event.api.getSelectedRows();
        if (bulkActionHelper.isActive) {
            const tasks: ITask[] = selectedRows.filter(taskIsNotPlaceholderNode);
            setSelectedForBulkAction(tasks);
            return;
        }

        setSelectedGridApi(event.api);
        if (selectedRows.length > 0) {
            if (taskIsNotPlaceholderNode(selectedRows[0])) {
                setSelectedRow(selectedRows[0]);
                displayDetails(true);
            }
            else {
                setSelectedRow(undefined);
                displayDetails(false);
            }
        }
    }

    const handleIsDirtyChanged = (dirty: boolean) => {
        setIsDirty(dirty);
    }

    const handleCancelConfirmDialogClose = () => {
        setCancelConfirmDialogIsOpen(false);
        setRowClickedTaskId(null);
    };


    const handleCancelConfirmDialogConfirm = () => {
        setCancelConfirmDialogIsOpen(false);
        setIsDirty(false);
        setShowDetails(false);
        setSelectedRow(null);
        if (rowClickedTaskId) {
            const rowNode = selectedGridApi?.getRowNode(rowClickedTaskId);
            if (rowNode) {
                rowNode.setSelected(true);
                setRowClickedTaskId(null);
            }
        } else {
            selectedGridApi?.deselectAll();
        }
        setRowClickedTaskId(null);
    };

    const handleTaskDocumentsUpdated = (task: ITask) => {
        if (selectedGridApi && task && task.taskId) {

            const rowNode = selectedGridApi.getRowNode(task.taskId);

            if (rowNode?.data) {
                rowNode.setData(task);
            }
        }
    }

    const handleTaskUpdated = (task: ITask) => {
        if (selectedGridApi && task && task.taskId) {
            const rowNode = selectedGridApi.getRowNode(task.taskId);

            // Handles the case in which the last Engagement Request has been removed (i.e. moved to draft)
            let updatedParentRowNode = selectedGridApi.getRowNode(rowNode?.parent?.id || '');
            if (updatedParentRowNode?.leafGroup) { // parent is entity group
                updatedParentRowNode = selectedGridApi.getRowNode(updatedParentRowNode?.parent?.id || '');
            }
            const taskChangedToDraftOrDeleted = taskStatusIsDraft(task) || taskStatusIsDeleted(task);
            const isLastVisibleChild = updatedParentRowNode?.childrenAfterFilter?.length === 1;
            const taskEntityWasUpdated = rowNode?.data.taskEntity !== task.taskEntity;
            if (rowNode?.data && rowNode.data !== task) {
                rowNode.setData(task);
            }

            const noTaskAvailableNode = {
                engagementName: selectedRow?.engagementName,
                title: engagementManagementPlaceholderNodeTitle,
                taskStatusDescription: '',
                taskNumber: '',
                taskId: `noTask${selectedRow?.engagementName}`
            };

            if (taskChangedToDraftOrDeleted) {
                isLastVisibleChild && selectedGridApi.applyTransaction({ add: [noTaskAvailableNode] });
                selectedGridApi?.deselectAll();
                setSelectedRow(null);
                handleIsDirtyChanged(false);
                setShowDetails(false);
                selectedGridApi.onFilterChanged();
            }

            if (taskEntityWasUpdated || taskChangedToDraftOrDeleted) {
                setUseEntityFieldForSelectedClientGrid(rowNode?.data.clientId);
            }
        }
    }

    const setUseEntityFieldForSelectedClientGrid = (clientId: string) => {
        if (selectedGridApi) {
            const tasks: ITask[] = [];
            selectedGridApi.forEachNode(node => {
                const task = node?.data;
                if (task && task.taskNumber) {
                    tasks.push(node?.data)
                }
            });

            const map = _.cloneDeep(clientToUseEntityGroupMap);
            const showEntityFieldGroupForClient = someTaskHasEntityValueAnd(tasks, (task: ITask) => !taskStatusIsDraft(task));
            map.set(clientId, showEntityFieldGroupForClient);
            setClientToUseEntityGroupMap(map);

            selectedGridApi.applyTransaction({ update: tasks });
        }
    }

    const cellClassRules: any = {
        [classes.draft]: (params: CellClassParams) => {
            if (!(params && params.data)) return false;
            if (params.colDef.field === "taskStatusDescription") {
                return taskStatusIsDraft(params.node.data)
            } else {
                return false
            }
        },
        [classes.disabledUser]: (params: CellClassParams) => {
            if (!(params && params.data)) return false;
            switch (params.colDef.field) {
                case "taskAssignees": {
                    if (clientUserList) {
                        params.data.taskAssignees.forEach((assignee: ITaskAssignee) => {
                            const user = clientUserList.find(
                                user => user.userId === params.data.assignedToUserId
                            );
                            if (!user?.isActive) {
                                return true
                            }
                        });
                        return false;
                    } else {
                        return false;
                    }
                }
                case "mossAdamsContactUserName": {
                    const user = mossAdamsUserList?.find(
                        user => user.userId === params.data.mossAdamsContactUserId
                    );
                    const userEngagement = userEngagementList.find(
                        ue =>
                            ue.userId === params.data.mossAdamsContactUserId &&
                            ue.engagementId === params.data.engagementId
                    );
                    return userEngagement ? !user?.isActive : true;
                }
                default: {
                    return false;
                }
            }
        }
    }

    const removeSelectedTaskFromGrid = () => {
        if (selectedGridApi && selectedRow?.taskId) {
            selectedGridApi.applyTransaction({ remove: [selectedRow] });
            selectedGridApi.refreshCells({ force: true });
            displayDetails(false);
        }
    }

    const rowClassRules = {
        [classes.complete]: (rowClassParams: RowClassParams) => {
            if (rowClassParams.node.childrenAfterGroup) {
                return false
            } else {
                return rowClassParams.data.taskStatusDescription === TaskStatus.Completed.string
            }
        },
        [classes.highPriority]: (rowClassParams: RowClassParams) => {
            if (rowClassParams.node.childrenAfterGroup) {
                return false
            }
            if (rowClassParams.data?.taskStatusDescription === TaskStatus.Completed.string) {
                return false
            }
            else {
                return rowClassParams.data?.taskPriorityDescription === TaskPriority.High.string
            }
        }
    }

    function colWidthSize(excelData: any[]) {
        let header = Object.keys(excelData[0]); // columns name

        var wscols = [];
        for (var i = 0; i < header.length; i++) {  // columns length added
            if (header[i] == "Engagement Name" || header[i] == "Title") {
                wscols.push({ wch: header[i].length + 25 })
            }
            else {
                wscols.push({ wch: header[i].length + 5 })
            }
        }

        return wscols;
    }

    function excelFileConfiguration(excelData: any[]) {
        let fileName = "EngagementRequests" + "_" + DateTime.now().toFormat('MM-dd-yyyy') + ".xlsx";

        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(excelData);

        ws['!cols'] = colWidthSize(excelData);

        XLSX.utils.book_append_sheet(wb, ws, "Engagements");

        XLSX.writeFile(wb, fileName);
    }

    const exportToExcel = async () => {

        let taskComments: ITaskCommentForExportVM[] = [];

        if (props.selectedEngagements) {
            const dataReadService = new DataReadService();

            await dataReadService.GetTaskCommentsForExport(props.selectedEngagements).then((response: IPlatformApiGetResult<ITaskCommentForExportVM>) => {
                if (response.status) {
                    taskComments = response.data;
                }
                else {
                    enqueueSnackbar(errorGettingComments, { variant: SnackbarVariantTypes.Error });
                }
            });
        }

        let excelData: any[] = [];

        let columnArray = getSelectedTabColumnApi()?.getAllDisplayedColumns() as Column[];

        getSelectedTabGridApi()?.forEachNodeAfterFilterAndSort(row => {
            let obj = Object.create({});

            const gridHeaderToImportFileHeaderMap = new Map<string, string>([
                ['Request Title', 'Title'],
                ['Assignee', 'External Assignee'],
            ]);

            if (row.data != undefined) {

                for (const element of columnArray) {
                    let columnId = element.getColId() as string;
                    if (columnId !== "ag-Grid-AutoColumn") {
                        let headerName = element.getColDef().headerName as string;
                        headerName = gridHeaderToImportFileHeaderMap.get(headerName) ? gridHeaderToImportFileHeaderMap.get(headerName)! : headerName;

                        obj[headerName] = excelDataFormatting(columnId, row.data[columnId]);
                    }
                }
                obj.Comments = taskComments.find(t => t.taskId == row.data.taskId)?.comments

                excelData.push(obj);

            }
        });

        excelFileConfiguration(excelData);
    };

    function excelDataFormatting(columnId: string, rowData: any) {

        let columnData = "";

        if (columnId != "ag-Grid-AutoColumn") {
            switch (columnId) {
                case "areaId": {
                    columnData = lookupValue(areas, rowData)
                    break;
                }
                case "phaseId": {
                    columnData = lookupValue(phases, rowData)
                    break;
                }
                case "taskPriorityId": {
                    columnData = lookupValue(priorities, rowData);
                    break;
                }
                case "taskAssignees": {
                    if (rowData && rowData.length) {
                        let assignees: string[] = []
                        rowData.forEach((assignee: ITaskAssignee) => {
                            assignees.push(assignee.displayName ? assignee.displayName : '');
                        });
                        columnData = assignees.join(", ");
                    } else {
                        columnData = '';
                    }
                    break;
                }
                case "taskTypeId": {
                    columnData = lookupValue(taskTypes, rowData);
                    break;
                }
                case "taskStatusId": {
                    columnData = lookupValue(statuses, rowData);
                    break;
                }
                case "socStatusId": {
                    columnData = lookupValue(socStatuses, rowData);
                    break;
                }
                case "mossAdamsContactUserId": {
                    columnData = userListValue(mossAdamsUserList, rowData);
                    break;
                }
                case "hasAttachments": {
                    columnData = rowData == true ? "Yes" : "No"
                    break;
                }
                default: {
                    columnData = rowData;
                    break;
                }
            }
        }

        return columnData;

    }

    const getEntityValue = (params: ValueGetterParams) => {
        const nodeData = params?.node?.data;
        if (!nodeData || taskIsPlaceholderNode(nodeData)) return null;
        const useEntityGroup = Boolean(clientToUseEntityGroupMap.get(nodeData.clientId));

        return nodeData.taskEntity !== null
            ? nodeData.taskEntity
            : useEntityGroup
                ? nullTaskEntityGroupName
                : null;
    }

    const updateExpandAllStatusForCurrentTab = () => {
        const api = getSelectedTabGridApi();
        if (api) {
            let shouldExpandAll = true;
            api?.forEachNode(node => {
                if (node.group && node.field === 'engagementName') {
                    shouldExpandAll = (shouldExpandAll && !node.expanded)
                }
            })
            setExpandAll(shouldExpandAll);
        }
    }

    const toggleEngagementExpansion = () => {
        const api = getSelectedTabGridApi();

        api?.forEachNode((node: RowNode) => {
            if (node.group && node.field === 'engagementName') {
                node.setExpanded(expandAll);
            }
        });
    }

    const onToggleBulkAction = (mode: BulkEngagementRequestActionOption = null) => {
        mode === 'download' && bulkActionHelper.setDownloadMode();
    }

    const onCompleteOrCancelBulkDownload = () => {
        bulkActionHelper.setInactive();
        getSelectedTabGridApi()?.deselectAll();
    }

    const onLoadView = () => {
        const client = groupedData?.[selectedTabIndex]?.client;
        const useEntityGroup = Boolean(clientToUseEntityGroupMap.get(client));
        getSelectedTabColumnApi()?.setColumnVisible("taskEntity", useEntityGroup);
    }

    return (<>
        <ConditionalVisiblity
            isVisible={lookupLists && !loadingLookups}
            useLoadingIndicator
            errorHandling={{
                hasError: Boolean(lookupListError),
                message: FailedToLoadEngagementManagementText
            }}
        >
            <RouteLeavingGuard
                when={props.isDirty}
                navigate={(path: string) => {
                    setIsDirty(false);
                    history.push(path);
                }}
            />
            <ConfirmDialog
                isOpen={cancelConfirmDialogIsOpen}
                onClose={handleCancelConfirmDialogClose}
                onConfirm={handleCancelConfirmDialogConfirm}
                title="Discard Changes"
                children={<Typography>You have unsaved changed, are you sure you would like to discard changes?</Typography>}
            />
            <Grid
                container
                spacing={10}
                className={classes.root}
            >
                <Grid item className={`${classes.gridRoot} ${classes.fullHeight}`}>
                    <div className={classes.pageHeader}>
                        <PageHeader withoutPadding>Engagement Requests</PageHeader>
                        <div className={classes.topControls}>
                            <DownloadTaskDocumentsButton
                                disabled={rawData?.length === 0}
                                className={`${classes.topIcons} ${classes.resetButton}`}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => onToggleBulkAction('download')}
                                popperProps={{
                                    tasks: selectedForBulkAction,
                                    onCompleteRequest: onCompleteOrCancelBulkDownload,
                                    onCancel: onCompleteOrCancelBulkDownload,
                                    confirmDisabled: !selectedForBulkAction.length
                                }}
                            />
                            <Tooltip title="Export Grid to Excel">
                                <Button size="small" color="primary" variant="contained" className={`${classes.topIcons} ${classes.resetButton} ${classes.svgIconButton}`}
                                    onClick={exportToExcel} >
                                    <Icon className={classes.excelSvgIcon}></Icon>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Show/Hide Completed Requests">
                                <Button size="small" color="primary" variant="contained" onClick={toggleCompletedFilter} className={`${classes.topIcons} ${classes.resetButton}`} >
                                    <AssignmentTurnedInIcon className={completedFiltered ? classes.completedFiltered : ""}></AssignmentTurnedInIcon>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Reset filters">
                                <Button size="small" color="primary" variant="contained" onClick={resetFilters} className={`${classes.topIcons} ${classes.resetButton} ${classes.svgIconButton}`} >
                                    <Icon className={classes.unfilterIcon}></Icon>
                                </Button>
                            </Tooltip>
                            <LayoutManagerModalButton
                                disabled={rawData?.length === 0}
                                modalProps={{
                                    gridApi: getSelectedTabGridApi(),
                                    columnApi: getSelectedTabColumnApi(),
                                    viewStorageKey: SavedViewKeys.engagementManagement,
                                    onClickResetColumns: resetColumns,
                                    onLoadView: onLoadView,
                                }}
                            />
                            <SearchIcon className={classes.topIcons} />
                            <div>
                                <FormControl>
                                    <Typography variant="subtitle1">
                                        <TextField
                                            label="Search"
                                            name="Search"
                                            type="search"
                                            value={searchField}
                                            onChange={(e: any) => {
                                                setSearchField(e.target.value);
                                            }}>
                                        </TextField>
                                    </Typography>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    {groupedData.length == 0 && !props?.selectedClients?.length && props.areSelectedClientEngagementsLoaded && !isLoading
                        ? <Typography variant="subtitle1">No Client Selected</Typography>
                        : <ConditionalVisiblity isVisible={!isLoading} classes={classes.container} useLoadingIndicator>
                            <Paper className={props.selectedClients?.length ? classes.body : ''}>
                                <ClientTabBar show={Boolean(props.selectedClients?.length)}
                                    expand={expandAll}
                                    clientNames={groupedData.map(data => data.key)}
                                    selectedTabIndex={selectedTabIndex}
                                    setSelectedTabIndex={setSelectedTabIndex}
                                    onClickExpand={toggleEngagementExpansion}
                                />
                                {groupedData.map((dataItem, index) => {
                                    const useEntityGroup = Boolean(clientToUseEntityGroupMap.get(dataItem.client));
                                    return (
                                        <TabPanel key={`tab-panel-${dataItem.client}`} className={classes.tabPanel} value={selectedTabIndex} index={index}>
                                            {dataItem.engagementCount === 0 ?
                                                <>
                                                    <Typography className={classes.noEngagementLabel} variant="subtitle1">No Active Engagements</Typography>
                                                </>
                                                :
                                                <>
                                                    {clientUserList != null &&
                                                        <Paper
                                                            className={`ag-theme-alpine ${classes.agTaskGrid}`}
                                                        >
                                                            <AgGridReact
                                                                onRowDataUpdated={(event: RowDataUpdatedEvent) => { event.api.redrawRows() }}
                                                                modules={AllModules}
                                                                rowData={dataItem.value}
                                                                suppressCellFocus={true}
                                                                suppressContextMenu={true}
                                                                suppressMenuHide={true}
                                                                suppressRowClickSelection={props.isDirty}
                                                                popupParent={document.querySelector('body')!}
                                                                defaultColDef={{
                                                                    sortable: true,
                                                                    resizable: true,
                                                                    editable: false,
                                                                    filter: true,
                                                                    floatingFilter: true,
                                                                }}
                                                                rowSelection={bulkActionHelper.isActive ? "multiple" : "single"}
                                                                rowMultiSelectWithClick={bulkActionHelper.isActive}
                                                                rowClassRules={rowClassRules}
                                                                editType={"none"}
                                                                getRowId={(row: any) => row.data.taskId}
                                                                onGridReady={(event: GridReadyEvent) => { onGridReady(event, index) }}
                                                                onSelectionChanged={handleRowSelected}
                                                                onRowClicked={handleGridRowClicked}
                                                                onFilterChanged={handleGridFilterChanged}
                                                                autoGroupColumnDef={{
                                                                    colSpan: (params: any) => { return params?.node?.group ? params?.columnApi?.getAllColumns()?.length || 6 : 1 },
                                                                    cellRendererParams: {
                                                                        suppressCount: true
                                                                    },
                                                                    width: 10,
                                                                    maxWidth: 10,
                                                                    headerClass: classes.autoColumnHeader,
                                                                }}
                                                                components={{
                                                                    headerCellRenderer: (params: GroupCellRendererParams) => EngagementGroupCellRenderer(params, props.currentUser?.isMossAdamsStaff),
                                                                    entityCellRenderer,
                                                                    attachmentsRenderer,
                                                                    dateRenderer,
                                                                    taskAssigneeCellRenderer: TaskAssigneeCellRenderer,
                                                                    requestedDateRenderer: (params: GroupCellRendererParams) => requestedDateRenderer(params, classes.overdueDate),
                                                                    restrictedRenderer,
                                                                }}
                                                                suppressDragLeaveHidesColumns
                                                                headerHeight={25}
                                                                isExternalFilterPresent={() => true}
                                                                doesExternalFilterPass={(rowNode: RowNode) => (rowNode.data && rowNode.data.taskStatusDescription !== TaskStatus.Draft.string && rowNode.data.taskStatusDescription !== TaskStatus.Deleted.string) || taskIsPlaceholderNode(rowNode.data)}
                                                                maintainColumnOrder={true}
                                                                isGroupOpenByDefault={(props: IsGroupOpenByDefaultParams) => (props.field === 'taskEntity')}
                                                                initialGroupOrderComparator={initialEngagementRequestsGroupOrderComparatorem}
                                                                onExpandOrCollapseAll={updateExpandAllStatusForCurrentTab}
                                                                onRowGroupOpened={updateExpandAllStatusForCurrentTab}
                                                                onRowDataChanged={updateExpandAllStatusForCurrentTab}
                                                                tooltipHideDelay={1000000}
                                                                tooltipShowDelay={1000}
                                                            >

                                                                <AgGridColumn
                                                                    field="isRestricted"
                                                                    headerName="Restricted"
                                                                    minWidth={60}
                                                                    maxWidth={60}
                                                                    cellRenderer="restrictedRenderer"
                                                                    headerComponent={() => <RestrictedIcon />}
                                                                    filterParams={{
                                                                        valueFormatter: (params: any) => {
                                                                            if (params.value == "true") {
                                                                                return "Restricted";
                                                                            }
                                                                            else {
                                                                                return "Unrestricted";
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                                <AgGridColumn
                                                                    field="engagementName"
                                                                    rowGroup={true}
                                                                    cellRenderer="agGroupCellRenderer"
                                                                    cellRendererParams={{
                                                                        innerRenderer: 'headerCellRenderer'
                                                                    }}
                                                                    filter={false}
                                                                    suppressColumnsToolPanel={true}
                                                                    hide={true}
                                                                    rowGroupIndex={0}
                                                                ></AgGridColumn>
                                                                <AgGridColumn
                                                                    field="taskEntity"
                                                                    rowGroup={true}
                                                                    cellRenderer="agGroupCellRenderer"
                                                                    minWidth={120}
                                                                    cellRendererParams={{
                                                                        innerRenderer: 'entityCellRenderer'
                                                                    }}
                                                                    valueGetter={getEntityValue}
                                                                    filter={true}
                                                                    sort={true}
                                                                    rowGroupIndex={1}
                                                                    headerName="Entity"
                                                                    suppressColumnsToolPanel={true}
                                                                    hide={!useEntityGroup}
                                                                    filterParams={{
                                                                        values: (params: SetFilterValuesFuncParams) => {
                                                                            const nodes: ITask[] = [];
                                                                            params.api.forEachNode(node => node?.data && nodes.push(node.data));
                                                                            const values: string[] = nodes.map((task: ITask) => {
                                                                                let value = '';
                                                                                if (task?.taskEntity && task.taskEntity !== null && taskIsNotPlaceholderNode(task)) {
                                                                                    value = task.taskEntity;
                                                                                }
                                                                                if (task.taskEntity === null) {
                                                                                    value = nullTaskEntityGroupName;
                                                                                }
                                                                                return value;
                                                                            });
                                                                            params.success(values);
                                                                        },
                                                                        refreshValuesOnOpen: true
                                                                    }}
                                                                    comparator={entityColumnComparator}
                                                                />
                                                                <AgGridColumn field="taskNumber" headerName="#" initialSort="asc"
                                                                    minWidth={105} maxWidth={115} suppressMenu={true} filter="agNumberColumnFilter"
                                                                    checkboxSelection={(params: CheckboxSelectionCallbackParams) => (bulkActionHelper.isActive && taskIsNotPlaceholderNode(params.data))}
                                                                    headerCheckboxSelection={bulkActionHelper.isActive}
                                                                    headerCheckboxSelectionFilteredOnly={bulkActionHelper.isActive}
                                                                />
                                                                <AgGridColumn field="title" headerName="Request Title" minWidth={150} filter="agTextColumnFilter" ></AgGridColumn>
                                                                <AgGridColumn field="description" headerName="Description" filter="agTextColumnFilter" minWidth={300}
                                                                    tooltipField='description'
                                                                    tooltipComponent={RequestDescriptionTooltip}
                                                                ></AgGridColumn>
                                                                <AgGridColumn field="notes" headerName="Notes" filter="agTextColumnFilter" minWidth={200}
                                                                    tooltipField='notes'
                                                                    tooltipComponent={RequestNotesTooltip}
                                                                ></AgGridColumn>
                                                                <AgGridColumn
                                                                    field="taskAssignees"
                                                                    headerName="Assignee(s)"
                                                                    minWidth={120}
                                                                    filter="agTextColumnFilter"
                                                                    getQuickFilterText={(params: any) => {
                                                                        return assignedToUserNameValueFormatter(params, clientUserList, userEngagementList);
                                                                    }}
                                                                    valueGetter={(params: ValueGetterParams) => {
                                                                        return assignedToUserNameValueGetter(params, clientUserList);
                                                                    }}
                                                                    cellClassRules={cellClassRules}
                                                                    valueFormatter={(params: ValueFormatterParams) => assignedToUserNameValueFormatter(params, clientUserList, userEngagementList)}
                                                                >
                                                                </AgGridColumn>
                                                                <AgGridColumn field="taskTypeDescription" headerName="Request Type" minWidth={165} filterParams={{ values: taskTypes.map(t => t.description) }}></AgGridColumn>
                                                                <AgGridColumn field="taskStatusDescription" headerName="Status" minWidth={180} maxWidth={180} filterParams={{ values: statuses.map(s => s.description) }} cellClassRules={cellClassRules}></AgGridColumn>
                                                                {dataItem.showSocStatusColumn &&
                                                                    <AgGridColumn field="socStatusDescription" headerName="SOC Status" minWidth={220} maxWidth={220} filterParams={{ values: socStatuses.map(s => s.description) }} cellClassRules={cellClassRules}></AgGridColumn>
                                                                }
                                                                <AgGridColumn
                                                                    field="requestedDate"
                                                                    headerName="Due Date"
                                                                    minWidth={180}
                                                                    cellRenderer="requestedDateRenderer"
                                                                    filter="agDateColumnFilter"
                                                                    filterParams={{
                                                                        inRangeInclusive: true,
                                                                        defaultOption: 'inRange'
                                                                    }}
                                                                    getQuickFilterText={(params: any) => {
                                                                        return LocalDateTimeString(params.value as Date, false);
                                                                    }}
                                                                ></AgGridColumn>
                                                                {dataItem.showAreaColumn &&
                                                                    <AgGridColumn field="areaDescription" headerName="Area" minWidth={120} filterParams={{ values: areas.map(a => a.description) }}></AgGridColumn>
                                                                }
                                                                {dataItem.showAreaColumn &&
                                                                    <AgGridColumn field="phaseDescription" headerName="Phase" minWidth={120} filterParams={{ values: phases.map(a => a.description) }}></AgGridColumn>
                                                                }
                                                                <AgGridColumn
                                                                    field="taskPriorityId"
                                                                    headerName="Priority"
                                                                    filterParams={{
                                                                        valueFormatter: (params: any) => { return lookupValue(priorities, params.value) }
                                                                    }}
                                                                    valueFormatter={(params: any) => { return lookupValue(priorities, params.value) }}
                                                                    cellClassRules={cellClassRules}
                                                                    minWidth={136}
                                                                    getQuickFilterText={(params: any) => { return lookupValue(priorities, params.value) }}
                                                                />
                                                                <AgGridColumn field="mossAdamsContactUserName" headerName="MA Contact" minWidth={120} filter="agTextColumnFilter" cellClassRules={cellClassRules} valueFormatter={(params: ValueFormatterParams) => mossAdamsContactUserNameValueFormatter(params, mossAdamsUserList, userEngagementList)}></AgGridColumn>
                                                                <AgGridColumn field="hasAttachments" headerName="Attachments" minWidth={130} maxWidth={150} cellRenderer="attachmentsRenderer" filterParams={{
                                                                    valueFormatter: (params: any) => {
                                                                        if (params.value == "true") {
                                                                            return "Yes";
                                                                        }
                                                                        else {
                                                                            return "No";
                                                                        }
                                                                    }
                                                                }}></AgGridColumn>
                                                                <AgGridColumn
                                                                    field="updatedDateTime"
                                                                    headerName="Last Modified"
                                                                    minWidth={180}
                                                                    cellRenderer="dateRenderer"
                                                                    filter="agDateColumnFilter"
                                                                    getQuickFilterText={(params: any) => {
                                                                        return LocalDateTimeString(params.value as Date, false);
                                                                    }}
                                                                    filterParams={{
                                                                        comparator: (filterLocalDateAtMidnight: any, cellValue: Date) => {
                                                                            var cellDate = new Date(cellValue.getFullYear(), cellValue.getMonth(), cellValue.getDate())
                                                                            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                                                                                return 0;
                                                                            }
                                                                            if (cellDate < filterLocalDateAtMidnight) {
                                                                                return -1;
                                                                            }
                                                                            if (cellDate > filterLocalDateAtMidnight) {
                                                                                return 1;
                                                                            }
                                                                            return 0;
                                                                        },
                                                                        inRangeInclusive: true,
                                                                        defaultOption: 'inRange'
                                                                    }}
                                                                ></AgGridColumn>
                                                                <AgGridColumn field="createdByUserName" headerName="Created By" filter="agTextColumnFilter" minWidth={150}></AgGridColumn>
                                                            </AgGridReact>
                                                        </Paper>
                                                    }
                                                </>
                                            }
                                        </TabPanel>
                                    );
                                })}
                            </Paper>
                        </ConditionalVisiblity>}
                </Grid>
                {showDetails && selectedRow &&
                    <Grid item className={classes.detailsPane} >
                        <EngagementManagementDetails
                            task={selectedRow}
                            onTaskUpdated={handleTaskUpdated}
                            onTaskDocumentUpdated={handleTaskDocumentsUpdated}
                            onClose={handleCloseDetails}
                            clientUserList={clientUserList ?? []}
                            mossAdamsUserList={mossAdamsUserList}
                            userEngagementList={userEngagementList.filter(
                                ue => ue.engagementId === selectedRow.engagementId
                            )}
                            areas={areas}
                            phases={phases}
                            priorities={priorities}
                            statuses={statuses}
                            taskTypes={taskTypes}
                            socStatuses={socStatuses}
                            uploadFolderId={uploadFolderId}
                            rootClass={`${classes.gridRoot} ${classes.detailTop}`}
                            overdueIndicatorClass={classes.overdueIndicator}
                            onIsDirtyChanged={handleIsDirtyChanged}
                            onDelete={removeSelectedTaskFromGrid}
                        />
                    </Grid>
                }
            </Grid>
        </ConditionalVisiblity>
    </>)
}

export default EngagementManagement;