import React from 'react'
import FileNameCollisionDialog from '../../components/common/FileNameCollisionDialog/FileNameCollisionDialog';
import { FileUploadContextProvider } from './FileUploadContextProvider';


export function FileUploadProviderWrapper(props: { children: React.ReactNode }) {

    return (
        <FileUploadContextProvider>
            <FileNameCollisionDialog />
            {props.children}
        </FileUploadContextProvider>
    )
}