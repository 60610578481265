import React from 'react'
import { ConfirmDialog } from '../../ConfirmDialog/ConfirmDialog'
import { Typography } from '@material-ui/core';
import { useFileUploadContext } from '../../../contexts/FileUploadContext/FileUploadContext';



export default function FileNameCollisionDialog() {
    const [open, setOpen] = React.useState(false);
    const {
        files,
        uploadNewVersionOnCollision,
        uploadWithRenameOnCollision,
        uploadingBackdrop,
        fileNameCollisions,
        cancelUpload
    } = useFileUploadContext();

    React.useEffect(() => {
        if (fileNameCollisions.length && !open) {
            setOpen(true);
        }
        else if (!fileNameCollisions.length && open) {
            setOpen(false);
        }
    }, [fileNameCollisions])


    const onClickClose = () => {
        setOpen(false);
        cancelUpload();
    }

    return (<>
        {uploadingBackdrop}
        <ConfirmDialog
            isOpen={open}
            onClose={onClickClose}
            onConfirm={uploadNewVersionOnCollision}
            onConfirmWithOptions={uploadWithRenameOnCollision}
            title="Duplicate files detected."
            confirmButtonText={`Upload as New Version${(files.length > 1) ? 's' : ''}`}
            confirmWithOptionsButtonText="Save as New"
            closeButtonText="Cancel"
            children={<>
                <Typography>
                    The following files already exist. How do you want to proceed?
                    <Typography>
                        {fileNameCollisions.map((file) => (<Typography>{file.name}</Typography>))}
                    </Typography>
                </Typography>
            </>}
        />
    </>
    )
}
