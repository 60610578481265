import React from "react"
import { Box, Button, Divider, Grid, List, ListItem, ListItemText, makeStyles, Typography, Paper, CircularProgress } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinkIcon from '@material-ui/icons/Link';
import IDocumentDetailsProps from "./IDocumentDetailsProps";
import ITask from "../../interfaces/ITask";
import IDocumentHistoryItem from "../../interfaces/IDocumentHistoryItem";
import DownloadFile from "../DownloadFile/DownloadFile";
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import { actionButtonVariant, } from "../../helpers/portalTheme";
import { LocalDateTimeString } from "../../helpers/DateHelpers";
import DetailHeader from "../common/DetailHeader/DetailHeader";
import TitleEditField from "../common/TitleEditField/TitleEditField";
import RequestNumberCellRenderer from "../../components/AgGridRenderers/RequestNumberCellRenderer";
import RequestTitleCellRenderer from "../../components/AgGridRenderers/RequestTitleCellRenderer";
import RequestDescriptionCellRenderer from "../../components/AgGridRenderers/RequestDescriptionCellRenderer";
import AsyncDropdown from "../common/AsyncDropdown/AsyncDropdown";
import DataReadService from "../../services/DataReadService";
import DocumentExpirationDate from "./DocumentExpirationDate";
import DocumentFileAccessDetails from "./DocumentAccess/DocumentFileAccessDetails";
import { DetailSectionHeader } from "../common/DetailSectionHeader/DetailSectionHeader";
import DetailSection from "../common/DetailSectionHeader/DetailSection/DetailSection";
import { useCopyLinkToClipboard } from "../../hooks/useCopyLinkToClipboard";
import { useSelector } from "react-redux";
import ICurrentUser from "../../interfaces/ICurrentUser";

const useStyles = makeStyles((theme) => {
    const elementsPadding = theme.spacing(6);
    return {
        paper: {
            minHeight: 0,
            height: '100%',
            overflowY: 'auto',
            maxHeight: '100%',
        },
        root: {
            minHeight: 0,
            width: '100%',
            maxHeight: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        list: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            flexGrow: 1,
            maxHeight: "400px",
            overflow: 'auto',
            paddingBottom: "0px",
            paddingTop: "0px",
            "&& .MuiListItem-root:nth-child(2n)": {
                backgroundColor: theme.palette.background.paper,
                border: `solid 1px ${theme.palette.mossadams.gray100}`,
            },
            "&& .MuiListItem-root:nth-child(2n+1)": {
                backgroundColor: theme.palette.mossadams.everyOtherRowColor
            },
            "& .MuiListItem-root:hover": {
                backgroundColor: `${theme.palette.mossadams.gray100} !important`
            },
        },
        message: {
            textAlign: 'center',
        },
        historyHeader: {
            fontSize: "1.3rem",
        },
        header: {
            "& .MuiDivider-root": {
                height: theme.spacing(1),
            }
        },
        historyHeaderSort: {
            paddingLeft: elementsPadding,
            paddingTop: theme.spacing(2),
            cursor: "pointer"
        },
        listDivider: {
            marginLeft: elementsPadding,
            marginRight: elementsPadding,
            height: theme.spacing(1),
        },
        listMarginRight: {
            marginRight: elementsPadding,
        },
        paddedBox: {
            padding: elementsPadding,
            display: "flex"
        },
        horizontalPaddedBox: {
            padding: `0 ${elementsPadding}px`,
            display: "flex"
        },
        sectionTitle: {
            color: theme.palette.primary.main,
            fontSize: "1.3rem",
            fontWeight: 600,
            letterSpacing: "1px",
            textTransform: "uppercase",
        },
        buttonRow: {
            display: "flex"
        },
        downloadFile: {
            flex: .25,
            textAlign: "center"
        },
        actionButton: {
            ...actionButtonVariant,
        },
        versionTextContainer: {
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        buttonContainer: {
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-evenly"
        },
        detailButton: {
            backgroundColor: theme.palette.primary.main,
            marginLeft: theme.spacing(2.5),
            "& .MuiButton-label": {
                color: "white",
            },
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
            },
            fontSize: "0.625rem",
        },
        overdue: {
            color: "red",
        },
        taskDetails: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: elementsPadding,
        },
        tasksGrid: {
            height: "120px",
            flex: "1 1 auto",
            "& .ag-center-cols-viewport": {
                overflowX: "hidden"
            }
        },
        accessManagement: {
            display: 'flex',
            flex: "1 1 auto",

            marginTop: theme.spacing(2.5),
            marginBottom: theme.spacing(2.5),
            minWidth: '100%',
            minHeight: 0
        },
        expirationDate: {
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    }
});

const DocumentDetails = (props: IDocumentDetailsProps): JSX.Element => {
    const classes = useStyles();
    const documentHistoryElementId = "documentHistoryList";
    const list = document.getElementById(documentHistoryElementId);
    const [listOverflowing, setListOverflowing] = React.useState<boolean>(false);
    const [tasks, setTasks] = React.useState<ITask[]>([]);
    const [selectedVersion, setSelectedVersion] = React.useState<IDocumentHistoryItem | undefined>();
    const [selectedFileAccessList, setSelectedFileAccessList] = React.useState<string[]>([]);
    const { getHierarchyLink } = useCopyLinkToClipboard();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    let dataReadService: DataReadService = new DataReadService();

    const handleListHeightConditionalStyles = () => {
        if (list?.scrollHeight && list?.clientHeight) {
            if (list?.scrollHeight > list?.clientHeight) {
                setListOverflowing(true);
            } else {
                setListOverflowing(false);
            }
        } else {
            setListOverflowing(false);
        }
    }
    React.useEffect(handleListHeightConditionalStyles, [list?.scrollHeight]);

    React.useEffect(() => {
        if (props.documentTasks) {
            setTasks(props.documentTasks);
        }
        else if (props.isLoadingDetails) {
            setTasks([])
        }
    }, [props.documentTasks, props.isLoadingDetails]);

    React.useEffect(() => {
        setSelectedVersion(undefined);
    }, [props.contentHierarchy])

    const handleRestoreVersion = (version: string = "") => {
        props.onRestoreVersion(props.documentId, version);
    }

    const components = {
        requestNumberCellRenderer: RequestNumberCellRenderer,
        requestTitleCellRenderer: RequestTitleCellRenderer,
        requestDescriptionCellRenderer: RequestDescriptionCellRenderer
    }

    const engagementTaskDetails = <Grid container key={'' + props.isLoadingDetails + tasks.length} className={classes.header + " " + classes.taskDetails}>
        <DetailSectionHeader title="Associated Requests" />
        <DetailSection>
            <div className={"ag-theme-alpine " + classes.tasksGrid}  >
                <AgGridReact
                    rowData={tasks}
                    modules={AllModules}
                    suppressCellFocus={true}
                    suppressContextMenu={true}
                    editType="none"
                    components={components}
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: true,
                        floatingFilter: false,
                        menuTabs: ['generalMenuTab', 'filterMenuTab']
                    }}
                    noRowsOverlayComponent={props.isLoadingDetails ?
                        () => <div><CircularProgress size={14} color='inherit' /></div>
                        : () => <div>No Associated Requests</div>}
                    headerHeight={25}
                >
                    <AgGridColumn
                        field="taskNumber"
                        headerName="#"
                        flex={1}
                        width={100}
                        minWidth={80}
                        maxWidth={100}
                        resizable={true}
                        editable={false}
                        sort={"asc"}
                        cellRenderer="requestNumberCellRenderer"
                    />
                    <AgGridColumn
                        field="title"
                        headerName="Title"
                        flex={1}
                        editable={false}
                        resizable={true}
                        minWidth={95}
                        maxWidth={180}
                        cellRenderer="requestTitleCellRenderer"
                    />
                    <AgGridColumn
                        field="description"
                        headerName="Description"
                        flex={1}
                        resizable={true}
                        minWidth={130}
                        maxWidth={350}
                        editable={false}
                        cellRenderer="requestDescriptionCellRenderer"
                    />
                </AgGridReact>
            </div>
        </DetailSection>

    </Grid>

    const listBody = (version?: IDocumentHistoryItem) => {
        if (!version) return null;
        return (
            <ListItem key={`${version.fileVersion}${version.updatedDateTime}`} button>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid item xs={12}>
                            <ListItemText
                                id={version.fileVersion}
                                primary={
                                    <Grid container>
                                        <Box fontWeight="fontWeightBold" m={1} className={classes.versionTextContainer} title={version.displayName}>
                                            {version.displayName}
                                        </Box>
                                        <Box m={1}>
                                            {`by ${version.updatedByUserName}`}
                                        </Box>
                                    </Grid>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box fontWeight="fontWeightMedium" m={1}>
                                {LocalDateTimeString(version.updatedDateTime)}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className={classes.buttonContainer}>
                        {version.isCurrentVersion == false &&
                            <>
                                <DownloadFile documentId={version.documentGuid} fileVersion={version.fileVersion} fileName={version.displayName}>
                                    <Button className={classes.detailButton}>
                                        Download
                                    </Button>
                                </DownloadFile>


                                <Button className={`${classes.detailButton} restoreButton`}
                                    onClick={() => handleRestoreVersion(version.fileVersion)}>
                                    Restore
                                </Button>
                            </>
                        }
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    const onUpdateAccess = (isPrivate: boolean, users: string[]) => {
        setSelectedFileAccessList(users);
        props.onUpdateAccess(isPrivate, users);
    }

    const isAssociatedWithRestrictedRequest = Boolean(tasks?.every(t => t.isRestricted)) && tasks?.length === 1;
    
    return (
        <Box className={classes.root}>
            <Box>
                <DetailHeader onClose={props.onClose}>
                    <Box className={classes.buttonRow}>
                        <DownloadFile className={classes.downloadFile}
                            documentId={props.documentId}
                            fileName={props.documentName} />
                        <Button className={classes.actionButton}
                            title="Copy File Link"
                            onClick={() => getHierarchyLink(props.contentHierarchy)}
                            color="primary"
                            startIcon={<LinkIcon />} />
                        <Button 
                            className={classes.actionButton}
                            title="Upload New Version"
                            onClick={props.onUploadNewVersion}
                            color="primary"
                            startIcon={<CloudUploadIcon />} />
                        <Button className={classes.actionButton}
                            title="Delete File"
                            onClick={props.onDocumentDelete}
                            color="primary"
                            startIcon={<DeleteIcon />} />
                    </Box>
                </DetailHeader>
            </Box>
            <Paper className={classes.paper}>
                <Box>
                    <Divider />
                    <TitleEditField data={props.contentHierarchy} onEdit={props.onEdit} />
                    <Box className={classes.paddedBox}>
                        <Grid container>
                            <Grid container item className={classes.accessManagement}>
                                <DocumentFileAccessDetails
                                    key={`${props.selectedDocument?.documentGuid}-${props.selectedDocument?.isPrivate}-${props.selectedDocument?.parentGuid}-${selectedFileAccessList.join(',')}`}
                                    hasRestrictedParent={Boolean(props.contentHierarchy?.parentIsRestricted)}
                                    isAssociatedWithRestrictedRequest={isAssociatedWithRestrictedRequest}
                                    document={props.selectedDocument}
                                    onUpdateAccess={onUpdateAccess}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {engagementTaskDetails}

                    <Box className={classes.horizontalPaddedBox}>
                        {props.contentHierarchy && <DocumentExpirationDate
                            key={props.contentHierarchy.id}
                            document={props.contentHierarchy}
                        />}
                    </Box>

                    <Box className={classes.paddedBox}>
                        <Grid container>
                            <DetailSectionHeader title="Version History" />
                            <DetailSection>
                                <List
                                    id={documentHistoryElementId}
                                    className={listOverflowing ? (classes.list + " " + classes.listMarginRight) : (classes.list)}
                                    dense
                                >
                                    <ListItem key={`${props.documentId}-${props.contentHierarchy?.name}-${props.contentHierarchy?.updatedDateTime}`}>
                                        <ListItemText>
                                            <AsyncDropdown
                                                getOptions={async () => await dataReadService.GetVersionHistory({ documentGuid: props.documentId })}
                                                selectedOption={selectedVersion}
                                                onSelectOption={(option) => {
                                                    option = option as IDocumentHistoryItem;
                                                    setSelectedVersion(option);
                                                }}
                                                optionDisplay={(option) => {
                                                    option = option as IDocumentHistoryItem;
                                                    return `${option.name} - ${LocalDateTimeString(option.updatedDateTime)}`;
                                                }}
                                                valueKey={"fileVersion"}
                                                label="Version"
                                                placeholder="Select a version to download or restore"
                                                noOptionsPlaceholder="No previous versions found"
                                            />
                                        </ListItemText>
                                    </ListItem>
                                    {listBody(selectedVersion)}
                                </List>
                            </DetailSection>

                        </Grid>
                    </Box>

                </Box>
            </Paper>
        </Box>
    );
}

export default DocumentDetails;