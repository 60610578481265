

import { Button, DialogActions, makeStyles, Paper, Popper, Typography } from '@material-ui/core'
import React from 'react'
import { HierarchyType } from '../../helpers/enums/HierarchyType';
import SpinnerBackdrop from '../common/SpinnerBackdrop'
import { BulkDocumentActionOption, IBulkDocumentActionHelper } from './useDocumentsBulkActionHelper';
import { hierarchyIsRestrictedFolder } from '../../helpers/ContentHierarchyHelpers';

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: 1200,
        "& .MuiPaper-root": {
            padding: '16px 12px 6px 12px',
        },
        "& .MuiDialogActions-root": {
            padding: '8px 6px 6px',
        },
    },

}));

interface IDocumentBulkActionPopperProps {
    bulkActionHelper: IBulkDocumentActionHelper
    onConfirmActions: OnConfirm
}

type OnConfirm = {
    [Property in Exclude<BulkDocumentActionOption, null>]: () => void;
}

function DocumentBulkActionPopper({ bulkActionHelper, onConfirmActions }: IDocumentBulkActionPopperProps) {
    const classes = useStyles();
    const { popperAnchorEl } = bulkActionHelper;

    const confirmButtonText = bulkActionHelper.isRestrictionMode ? 'Change' : 'OK';

    const getOnConfirm = () => {
        const mode = bulkActionHelper.value;
        if (mode == null) {
            return () => { };
        }
        return onConfirmActions[mode]
    }

    const getMessage = () => {
        let message = `Please select the file(s) that you want to ${bulkActionHelper.value}, then click OK.`;

        if (bulkActionHelper.isRestrictionMode) {
            return <>
                <div>Please select files where you would like to change access.</div>
                <Typography>Note that you can only manage access for files from a single uploader and within a single engagement at a time.</Typography>
            </>
        }
        if (bulkActionHelper.isDeleteMode) {
            if (bulkActionHelper.selected.some(hierarchy => hierarchyIsRestrictedFolder(hierarchy))) {
                return <>
                    <div>{message}</div>
                    <Typography>Restricted folders and any files they contain will be permanently deleted. Do you want to proceed?</Typography>
                </>
            }
        }

        return message;
    }

    const selectedFileCount = bulkActionHelper.selected.filter(h => h.hierarchyTypeId === HierarchyType.File.id).length;

    return (
        <Popper className={classes.popper} id={Boolean(popperAnchorEl) ? 'confirm-popper' : undefined} anchorEl={popperAnchorEl} placement='left-end' open={Boolean(popperAnchorEl)} >
            <Paper elevation={5}>
                <SpinnerBackdrop isActive={bulkActionHelper.submitting} />
                <Typography variant='h5'>
                    {getMessage()}
                </Typography>
                <DialogActions>
                    <Typography>
                        {selectedFileCount} {selectedFileCount == 1 ? 'file' : 'files'} selected
                    </Typography>
                    <Button onClick={getOnConfirm()}
                        size='small' color='primary' disabled={!bulkActionHelper.hasSelections()} variant='outlined'>
                        {confirmButtonText}
                    </Button>
                    <Button onClick={bulkActionHelper.onToggleBulkAction} size='small' color='primary' variant='outlined'>
                        Cancel
                    </Button>
                </DialogActions>
            </Paper>
        </Popper>
    )
}

export default DocumentBulkActionPopper