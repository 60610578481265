import React from "react";
import DataWriteService from "../../../services/DataWriteService";
import IDocumentsUploadModel from "../../../interfaces/IDocumentsUploadModel";
import { useSnackbar } from "notistack";
import IPlatformApiPostResult from "../../../interfaces/IPlatformApiPostResult";
import { validateFiles } from "../../../Validators/FileValidators";
import { SnackbarVariantTypes } from "../../../helpers/enums/enums";
import { Utils } from "../../../utilities/utils";
import { useDispatch, useSelector } from "react-redux";
import { setClientEngagementSelectorDisabled } from "../../../store/actions";
import { AxiosProgressEvent } from "axios";
import ICurrentUser from "../../../interfaces/ICurrentUser";

export const errorUploadingFileText = 'There was an error uploading the file(s).';
export const successfullyUploadedFileText = 'File(s) uploaded successfully.';
export const restrictedRequestAttachmentSuccess = "The file(s) uploaded successfully and only the Assignee(s) of the request have access.";
export const maUserUploadWarning = 'If you are intending to limit access, please utilize the restriction options.';

export const useFileUpload = () => {
    const [files, setFiles] = React.useState<File[]>([]);
    const [uploadProgress, setUploadProgress] = React.useState<number>(0);
    const [isUploading, setIsUploading] = React.useState(false);
    const [uploadResult, setUploadResult] = React.useState<IPlatformApiPostResult | null>(null);
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const updateSelectedFiles = (files: File[]) => {
        if (!files.length) {
            setFiles(files);
            return;
        }
        setUploadProgress(0);

        const { fileSetSizeValidation, validFiles, invalidFiles } = validateFiles(files);

        if (fileSetSizeValidation.isNotValid) {
            enqueueSnackbar(fileSetSizeValidation.error, { variant: SnackbarVariantTypes.Error });
            return;
        }
        if (invalidFiles.length) {
            invalidFiles.forEach(invalidFile => {
                enqueueSnackbar(invalidFile.validation.error, { variant: SnackbarVariantTypes.Error });
            })
            return;
        }

        setFiles(validFiles);
    }

    const handleUploadProgress = (e: AxiosProgressEvent) => {
        if (e.loaded && e.total) {
            let percentComplete = Math.round((e.loaded * 100) / e.total);

            if (percentComplete == 100) {
                percentComplete = 99;
            }
            setUploadProgress(percentComplete);
        }
    }

    const showWarningMessage = (): boolean => {
        return currentUser.isMossAdamsStaff;
    }

    const handleUpload = async (model: IDocumentsUploadModel) => {
        if (files.length && !isUploading) {
            dispatch(setClientEngagementSelectorDisabled(true));
            setIsUploading(true);
            setUploadResult(null);
            model.documentFiles = files;

            const service = new DataWriteService();
            let result: IPlatformApiPostResult = Boolean(model.task)
                ? await service.PostAttachments(model, handleUploadProgress)
                : await service.PostDocuments(model, handleUploadProgress);
                
            setUploadProgress(100);
            setUploadResult(result);

            if (result.status) {
                setFiles([]);
                enqueueSnackbar(getSuccessMessage(model), { variant: SnackbarVariantTypes.Success, persist: true });
                if (showWarningMessage()) {
                    enqueueSnackbar(maUserUploadWarning, { variant: SnackbarVariantTypes.Warning, persist: true });   
                }
            }
            else {
                const errors = [getErrorMessage(model), ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error, persist: true });
            }
            setIsUploading(false);
            dispatch(setClientEngagementSelectorDisabled(false));
            return result;
        }
    }

    const getSuccessMessage = (model: IDocumentsUploadModel) => {
        if (model.task?.isRestricted) {
            return restrictedRequestAttachmentSuccess;
        }
        return successfullyUploadedFileText;
    }

    const getErrorMessage = (model: IDocumentsUploadModel) => {
        return errorUploadingFileText;
    }


    return { files, uploadProgress, updateSelectedFiles, handleUpload, isUploading, uploadResult }
}