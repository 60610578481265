import { Button, DialogActions, DialogContent, makeStyles, Typography, Grid, Tooltip } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React from 'react'
import { SnackbarVariantTypes } from '../../helpers/enums/enums'
import { IUpdateDocumentAccessDto } from '../../interfaces/IUpdateDocumentPermissionsDto'
import DataWriteService from '../../services/DataWriteService'
import { Utils } from '../../utilities/utils'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'
import { IBulkDocumentActionHelper } from '../documentManagement/useDocumentsBulkActionHelper'
import MADialogTitle from '../MADialogTitle/MADialogTitle'
import BulkUpdateAccessDialog from './BulkUpdateAccessDialog'
import RestrictedUserAutocomplete, { RestrictedUserOption } from './RestrictedUserAutocomplete'
import usePermittedUsers from '../../hooks/usePermittedUsers'

type NewRestrictedFileUploadProps = {
    onUpload: (users: string[], isRestricted: boolean) => void;
    currentUserId: string;
    folderId: string;
}

interface RestrictedFileUserAssociationDialogContentProps {
    onClose: () => void;
    children?: JSX.Element;
    className?: string;
    submitDisabled?: boolean;
    title: string;
}

interface UpdateDocumentAccessVariant {
    document?: UpdateDocumentAccessModel;
    bulkDocumentHelper?: IBulkDocumentActionHelper;
    onUpdateAccess: (isPrivate: boolean, usersWithAccess: string[]) => void;
    fileUploadProps?: never;
}

interface FileUploadVariant {
    document?: never;
    onUpdateAccess?: never;
    bulkDocumentHelper?: never;
    fileUploadProps: NewRestrictedFileUploadProps
}

export interface UpdateDocumentAccessModel {
    documentId: string;
    createdByUserId: string;
    parentId: string;
    isPrivate: boolean;
}
    
type Props = (RestrictedFileUserAssociationDialogContentProps & UpdateDocumentAccessVariant)
    | (RestrictedFileUserAssociationDialogContentProps & FileUploadVariant)

const useStyles = makeStyles((theme) => {
    return {
    removeRestrictionsButton: {
        justifySelf: 'flex-start',
        alignSelf: 'center',
    },
    buttonGrid: {
        justifyContent: 'space-between',
    },
    uploadRestrictedFilesMessage: {
        paddingY: theme.spacing(2),
        fontSize: "1rem !important",
    },
    icon: {
        verticalAlign: 'middle',
    },
}})


function RestrictedFileUserAssociationDialogContent(props: Props) {
    const { onClose, fileUploadProps, submitDisabled, children, className, title, document, onUpdateAccess, bulkDocumentHelper } = props;

    const permittedUsers = usePermittedUsers();
    const [submitting, setSubmitting] = React.useState(false);
    const [showRemoveAccessRestrictionsDialog, setShowRemoveAccessRestrictionsDialog] = React.useState(false);
    const [showBulkAccessUpdateDialog, setShowBulkAccessUpdateDialog] = React.useState(false);
    const [removeAll, setRemoveAll] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const onUpload = fileUploadProps?.onUpload;
    const isUpdateAccessVariant = !!(document && document.createdByUserId && onUpdateAccess);
    const selectedDocumentIsUnrestricted = isUpdateAccessVariant && !document.isPrivate;
    const isBulkUpdateVariant = Boolean(bulkDocumentHelper?.isRestrictionMode);

    const uploadedByUserId = fileUploadProps?.currentUserId ?? document?.createdByUserId;
    const folderId = fileUploadProps?.folderId ?? document?.parentId;
    if (!uploadedByUserId || !folderId)
        return null;

    const onSubmit = async () => {
        if (submitting) return;

        if (isBulkUpdateVariant) {
            return setShowBulkAccessUpdateDialog(true);
        }

        const selectedUsers = removeAll ? [] : permittedUsers.getSelectedPermittedUserIdsForRequest();
        const isRestricted =  !removeAll && permittedUsers.hasSelections;

        setSubmitting(true);
        if (onUpload) {
            onUpload(selectedUsers, isRestricted);
        }
        else if (isUpdateAccessVariant) {
            const dto: IUpdateDocumentAccessDto = {
                documentId: props.document!.documentId,
                userIds: selectedUsers,
                restricted: isRestricted
            }
            const dataWriteService = new DataWriteService();
            const response = await dataWriteService.UpdateDocumentAccessPermissions(dto);
            if (response.status) {
                const successMessage =  isRestricted ? "File access restrictions successfully updated." : "All access restrictions were removed from the selected file.";
                enqueueSnackbar(successMessage, { variant: SnackbarVariantTypes.Success });
                onUpdateAccess(isRestricted, permittedUsers.getPermittedUserIds());
                onClose();
            }
            else {
                Utils.enqueueMultiLineSnackbar([...response.errorMessages], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }
        setSubmitting(false);
        setRemoveAll(false);
    }

    const onSubmitBulkUpdate = () => {   
        const isRestricted =  !removeAll && permittedUsers.hasSelections;

        const users = removeAll ? [] : permittedUsers.getSelectedPermittedUserIdsForRequest();
        bulkDocumentHelper?.execute(onUpdateAccess, users, isRestricted);
        setShowBulkAccessUpdateDialog(false);
        setRemoveAll(false);
        onClose();
    }

    const onClickRemoveAll = () => {
        setRemoveAll(true);
        if (isBulkUpdateVariant) {
            return setShowBulkAccessUpdateDialog(true);
        }
        setShowRemoveAccessRestrictionsDialog(true);
    }

    const shouldUserBeSelected = (option: RestrictedUserOption) => {
        const isUploadVariant = !isUpdateAccessVariant && !isBulkUpdateVariant;
        const selectUsersWithAccess = !isBulkUpdateVariant;
        const selectMAUsers = (isUpdateAccessVariant && !document.isPrivate)  || isBulkUpdateVariant || isUploadVariant;

        return ((selectUsersWithAccess && option.hasAccess)
            || option.userId === uploadedByUserId
            || (selectMAUsers && option.isMossAdamsStaff));
    }

    const showMAUserSelectionMessage = (onUpload || isBulkUpdateVariant || selectedDocumentIsUnrestricted);

    return (
        <>
            <MADialogTitle id="upload-restricted-file-dialog-title" onClose={onClose}>
                {title}
            </MADialogTitle>
            <DialogContent className={className}>
                {children}
                { showMAUserSelectionMessage &&
                    <Typography className={classes.uploadRestrictedFilesMessage}>
                        The file(s) will be accessible to all Moss Adams users unless you de-select them.
                    </Typography>
                }
                <Typography className={classes.uploadRestrictedFilesMessage}>
                     Click on the <ArrowDropDown className={classes.icon}/> expand icon to view the members of this portal. Selected users will be able to see and access this file.
                </Typography>
                <RestrictedUserAutocomplete
                    permittedUsers={permittedUsers}
                    documentId={document?.documentId}
                    folderId={folderId}
                    createdByUser={{
                        userId: uploadedByUserId,
                        label: '(Owner)'
                    }}
                    shouldUserBeSelected={shouldUserBeSelected}
                />
                <DialogActions>
                    {onUpload ?
                        <Button color="primary" fullWidth={true} variant="outlined" onClick={() => onSubmit()} disabled={submitDisabled}>
                            Upload
                        </Button>
                        : <Grid container className={classes.buttonGrid}>
                            <Grid item className={classes.removeRestrictionsButton}>
                                {document?.isPrivate &&
                                    <Button color="primary" variant="outlined" size='small' onClick={onClickRemoveAll}>Remove Restrictions</Button>
                                }
                            </Grid>
                            <Grid item>
                                <Button disabled={submitting || !permittedUsers.hasSelections} style={{marginRight: '0.5rem'}} color="primary" variant="outlined" onClick={() => onSubmit()}>Save</Button>
                                <Button disabled={submitting} color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    }
                </DialogActions>
            </DialogContent>

            <ConfirmDialog
                title={'Remove Access Restrictions?'}
                isOpen={showRemoveAccessRestrictionsDialog}
                onConfirm={onSubmit}
                onClose={() => { 
                    setShowRemoveAccessRestrictionsDialog(false); 
                    setRemoveAll(false); 
                }}
            >
                <Typography>
                    You are about to remove all restrictions on this file. Do you want to proceed?
                </Typography>
            </ConfirmDialog>

            <BulkUpdateAccessDialog
                isOpen={showBulkAccessUpdateDialog}
                onConfirm={onSubmitBulkUpdate}
                onClose={() => {
                    setShowBulkAccessUpdateDialog(false);
                    setRemoveAll(false);
                }}
                removeAll={removeAll}
            />

        </>
    )
}


export default RestrictedFileUserAssociationDialogContent;