import IPlatformApiPostResult from "../../interfaces/IPlatformApiPostResult";
import ITask from "../../interfaces/ITask";

export interface UploadParams {
    folderId: string;
    isRestricted: boolean;
    permittedUsers: string[];
    suppressNotifications: boolean;
    newVersionDocumentId: string | null;
    task: ITask | null;
    uploadSuccessCallback: (result: IPlatformApiPostResult) => void;
    uploadFailureCallback: (result: IPlatformApiPostResult) => void;
    onEndedCallback: () => void;
}

export const defaultUploadParams: UploadParams = {
    folderId: "",
    isRestricted: false,
    permittedUsers: [],
    suppressNotifications: true,
    newVersionDocumentId: null,
    task: null,
    uploadSuccessCallback: () => {},
    uploadFailureCallback: () => {},
    onEndedCallback: () => {},
};

export const createUploadParams = (): UploadParamsBuilder => {
    return new UploadParamsBuilder();
};

class UploadParamsBuilder {
    private params: Partial<UploadParams>;

    constructor() {
        this.params = { ...defaultUploadParams };
    }

    withFolderId(id: string): UploadParamsBuilder {
        this.params.folderId = id;
        return this;
    }

    withRestriction(isRestricted: boolean): UploadParamsBuilder {
        this.params.isRestricted = isRestricted;
        return this;
    }

    withPermittedUsers(users: string[]): UploadParamsBuilder {
        this.params.permittedUsers = users;
        return this;
    }

    withNotifications(sendNotifications: boolean): UploadParamsBuilder {
        this.params.suppressNotifications = !sendNotifications;
        return this;
    }

    withNewVersionId(id: string | null): UploadParamsBuilder {
        this.params.newVersionDocumentId = id;
        return this;
    }

    withRequestAttachment(task: ITask | null): UploadParamsBuilder {
        this.params.task = task;
        return this;
    }

    onSuccess(callback: (result: IPlatformApiPostResult) => void): UploadParamsBuilder {
        this.params.uploadSuccessCallback = callback;
        return this;
    }

    onFailure(callback: (result: IPlatformApiPostResult) => void): UploadParamsBuilder {
        this.params.uploadFailureCallback = callback;
        return this;
    }
    
    onEnded(callback: () => void): UploadParamsBuilder {
        this.params.onEndedCallback = callback;
        return this;
    }

    build(): UploadParams {
        if (!Boolean(this.params.folderId)) {
            throw new Error('folderId is required');
        }
        return this.params as UploadParams;
    }
}