import React, { useCallback } from "react";
import { IDocumentManagementProps } from "./IDocumentManagementProps";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button, Tooltip, FormControl, FormControlLabel, Switch, TextField } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import DataReadService from "../../services/DataReadService";
import DataWriteService from '../../services/DataWriteService';
import { useDispatch } from "react-redux";
import { setClientEngagementSelectorDisabled } from "../../store/actions";
import FileUploadDialog from "../FileUploadDialog/FileUploadDialog";
import { HierarchyType } from '../../helpers/enums/HierarchyType';
import { FolderDialog } from "../FolderDialog/FolderDialog";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { VariantType, useSnackbar } from 'notistack';
import IDocument from "../../interfaces/IDocument";
import ITask from "../../interfaces/ITask";
import DocumentDetails from "./DocumentDetails";
import portalTheme from "../../helpers/portalTheme";
import PageHeader from "../PageHeader/PageHeader";
import { Utils } from "../../utilities/utils";
import { Route, useHistory, useLocation } from "react-router-dom";
import { GridApi, ColumnApi, RowNode, GridReadyEvent } from "@ag-grid-enterprise/all-modules";
import { agGridThemeOverride } from "../../helpers/portalTheme";
import SearchIcon from '@material-ui/icons/Search';
import { SnackbarVariantTypes } from "../../helpers/enums/enums";
import TaskListDnd from "./TaskListDnD";
import _ from "lodash";
import { saveAs } from 'file-saver';
import useDocumentsBulkActionHelper from "./useDocumentsBulkActionHelper";
import DocumentBulkActionPopper from "./DocumentBulkActionPopper";
import TooltipIconButton from "../common/TooltipIconButton/TooltipIconButton";
import BulkDocumentActionButtons from "./BulkDocumentActionButtons/BulkDocumentActionButtons";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import DocumentGrid from "./DocumentGrid/DocumentGrid";
import IContentHierarchyGetDto from "../../interfaces/IContentHierarchyGetDto";
import { CurrentDocumentExpireDate } from "../../interfaces/IDocumentExpireDateDto";
import { SavedViewKeys } from "../../helpers/SavedViewKeys";
import LayoutManagerModalButton from "../SavedViewModal/LayoutManagerModalButton";
import BulkRestrictedFileAssociationDialog from "../RestrictedFileAssociationDialog/BulkRestrictedFileAssociationDialog";
import ConditionalVisiblity from "../common/ConditionalVisibility/ConditionalVisiblity";
import IDeleteFolderOrDocumentModel from "../../interfaces/IDeleteFolderOrDocumentModel";
import { buildPath, getAllDescendentItems, getParentGridDataForNewChild, getUpdatedSubtreeForUpdatedFolder, hierarchyIsFile, hierarchyIsFolder, hierarchyIsRestrictedFolder } from "../../helpers/ContentHierarchyHelpers";
import DetailsPane from "../common/DetailsPane/DetailsPane";
import FolderDetails from "./FolderDetails";
import { ExpandMore } from "@material-ui/icons";
import { useSelectedClientsAndEngagements } from "../../hooks/useSelectedClientsAndEngagements";

export const errorRetrievingHistoryText = 'There was an error retrieving the history';
export const errorDeletingFileText = 'There was an error deleting the file.';
export const errorRestoringFileText = 'There was an error restoring the file version.';
export const errorUpdatingDocumentText = 'There was an error updating the file.';
export const successfullyDeletedFileText = 'File deleted successfully.';
export const successfullyRestoredFileText = 'File version successfully restored.';
export const successfullyUpdatedDocumentText = 'File updated successfully.';
export const successfullyDeletedFolderText = "Folder deleted successfully";
export const errorDeletingFolderText = "There was an error deleting the folder.";
export const errorRequestNotFoundText = "The request was not found.";
export const errorFetchingContentHierarchy = "There was a problem fetching data, please refresh the page and try again";
export const errorFileIsRestricted = "The file is restricted; please contact Moss Adams for access."
export const errorSeverity: VariantType = 'error';
export const successSeverity: VariantType = 'success';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "nowrap",
        flex: 1,
        ...agGridThemeOverride
    },
    gridComponents: {
        padding: "20px",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    gridPane: {
        display: 'flex',
        flexDirection: 'column',
        flex: "1 1 auto",
        maxWidth: "75%",
        minWidth: "750px"
    },
    gridSpan: {
        height: '100%'
    },
    requestsPane: {
        minHeight: "100%",
        minWidth: "500px",
        width: '50%',
        maxWidth: '50%',
        flex: '1 1 auto'
    },
    pageHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    topControls: {
        display: "flex",
        alignItems: "center",
        paddingBottom: theme.spacing(10),
        justifyContent: "flex-end",
    },
    resetButton: {
        marginRight: theme.spacing(5),
        minWidth: theme.spacing(20),
    },
    topIcons: {
        marginTop: theme.spacing(8),
    },
    tableRow: {
        "&:hover": { backgroundColor: `${portalTheme.palette.mossadams.gray100} !important` }
    },
    detailPane: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary.contrastText,
    },
    checkbox: {
        height: '28px',
        marginTop: '-4px',
    },
    requestMode: {
        paddingTop: theme.spacing(5),
        "& .MuiSwitch-thumb": {
            marginTop: theme.spacing(-1.5)
        },
        "& .MuiSwitch-root": {
            marginTop: theme.spacing(3),
        },
        "& .MuiTypography-root": {
            marginTop: theme.spacing(2),
        },

    },
    excelSvgIcon: {
        maskImage: 'url(/images/excelIcon.svg)',
        transform: 'scale(0.85)'
    },
    unfilterIcon: {
        maskImage: 'url(/images/UnfilterIcon.svg)',
        transform: 'scale(1.25)'
    },

    svgIconButton: {
        // The span for the SVG image is nested in the span tag for the label so that 
        // is why it is necessary to use two child combinator to apply the style
        '& span > span': {

            backgroundColor: "#fff",
            display: 'flex',
            height: '24px',
            '&:hover': {
                backgroundColor: "#fff",
                cursor: 'pointer'
            },
            maskSize: 'contain',
            width: '24px'
        }
    },
}));

const DocumentManagement = (props: IDocumentManagementProps): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar();
    const [hierarchy, setHierarchy] = React.useState<IContentHierarchy[] | null>();
    const [errorLoadingHierarchy, setErrorLoadingHierarchy] = React.useState(false);
    const [fileUploadDialogIsOpen, setFileUploadDialogIsOpen] = React.useState(false);
    const [showDocumentDetails, setShowDocumentDetails] = React.useState(false);
    const [showFolderDetails, setShowFolderDetails] = React.useState(false);
    const [selectedDocumentDetails, setSelectedDocumentDetails] = React.useState<IDocument | null>(null);
    const [deleteFileDialogOpen, setDeleteFileDialogOpen] = React.useState(false);
    const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = React.useState(false);
    const [folderDialogOpen, setFolderDialogOpen] = React.useState(false);
    const [restrictedFolderDialogOpen, setRestrictedFolderDialogOpen] = React.useState(false);
    const [folderSizeWarningDialogOpen, setFolderSizeWarningDialogOpen] = React.useState(false);
    const [folderContentsWarningDialogOpen, setFolderContentsWarningDialogOpen] = React.useState(false);
    const [selectedContentHierarchy, setSelectedContentHierarchy] = React.useState<IContentHierarchy | null>(null);
    const [selectedFolder, setSelectedFolder] = React.useState<IContentHierarchy | null>(null);
    const [documentTasks, setDocumentTasks] = React.useState<ITask[] | null>(null);
    const [isLoadingDocumentDetails, setIsLoadingDocumentDetails] = React.useState(false);
    const [uploadNewVersion, setUploadNewVersion] = React.useState(false);
    const [isFolderRename, setIsFolderRename] = React.useState(false);
    const [gridApi, setGridApi] = React.useState<GridApi>();
    const [columnApi, setColumnApi] = React.useState<ColumnApi>();
    const [searchField, setSearchField] = React.useState("");
    const [requestMode, setRequestMode] = React.useState(false);
    const [showRestrictedFileAssociationDialog, setShowRestrictedFileAssociationDialog] = React.useState(false);
    const [uploadRestrictedFile, setUploadRestrictedFile] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)
    const [expandAllFolders, setExpandAllFolders] = React.useState(false);

    const bulkActionHelper = useDocumentsBulkActionHelper();
    const { selectedClientAndEngagementIds, selectedClients, selectedEngagements } = useSelectedClientsAndEngagements();

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (bulkActionHelper.isActive) {
            handleDetailsClose();
            setRequestMode(false);
        } else {
            gridApi?.deselectAll();
            bulkActionHelper.setSelectedForBulkAction([]);
            bulkActionHelper.setSubmitting(false);
        }
    }, [bulkActionHelper.value]);

    React.useEffect(() => {
        if (gridApi) {
            gridApi.setQuickFilter(searchField);
        }
    }, [searchField]);

    React.useEffect(() => {
        if (requestMode === true) {
            setShowDocumentDetails(false);
            setSelectedContentHierarchy(null);
        }
        gridApi?.deselectAll();
        setSelectedFolder(null);
    }, [requestMode]);

    React.useEffect(() => {
        getContentHierarchy();
    }, [selectedClientAndEngagementIds, props.areSelectedClientEngagementsLoaded]);

    const getContentHierarchy = async () => {
        if (props.areSelectedClientEngagementsLoaded && selectedClients && selectedClients.length > 0) {
            const dataReadService = new DataReadService();
            const data: IContentHierarchyGetDto = { clientIds: selectedClients };

            if (selectedEngagements) {
                data.engagementIds = selectedEngagements;
            }

            gridApi?.setRowData([]);
            bulkActionHelper.setInactive();
            setIsLoading(true);
            dispatch(setClientEngagementSelectorDisabled(true));

            const response = await dataReadService.GetContentHierarchy(data);
            if (response.status) {
                let data = buildPath(response.data);

                if (!Boolean(hierarchy)) {
                    setHierarchy(data);
                }
                else {
                    gridApi?.setRowData([]);
                    gridApi?.applyTransaction({ add: data });
                }
                setErrorLoadingHierarchy(false);
            }
            else {
                enqueueSnackbar(errorFetchingContentHierarchy, { variant: errorSeverity, preventDuplicate: true, autoHideDuration: null });
                setHierarchy(null);
                gridApi?.setRowData([]);
                setErrorLoadingHierarchy(true);
            }
        }
        else {
            setHierarchy(null);
            gridApi?.setRowData([]);
        }

        if (props.areSelectedClientEngagementsLoaded) {
            setIsLoading(false);
            setSelectedContentHierarchy(null);
            setSelectedFolder(null);
            dispatch(setClientEngagementSelectorDisabled(false));
        }
    }

    function selectHierarchyItem(documentId: string) {
        if (gridApi && documentId) {
            let rowNode: (RowNode | undefined);
            gridApi.refreshCells({ force: true });
            rowNode = gridApi.getRowNode(documentId);
            if (rowNode?.data) {
                const data: IContentHierarchy = rowNode.data;
                rowNode.setSelected(true);
                gridApi.ensureNodeVisible(rowNode);

                if (hierarchyIsFile(data)) {
                    setSelectedContentHierarchy(data);
                    handleShowDetails(data);
                }
                else {
                    setSelectedFolder(data);
                    enableDocumentDetailsPane();
                }
            }
        }
    }

    // Responsible for ensuring current selection matches current path
    React.useEffect(() => {
        if (props.areSelectedClientEngagementsLoaded && gridApi) {
            const hierarchyId = Utils.getUrlGUID();
            if (hierarchyId) {
                selectHierarchyItem(hierarchyId);
            }
        }
    }, [location.pathname, props.areSelectedClientEngagementsLoaded, gridApi, hierarchy]);

    React.useEffect(() => {
        if (props.routeId && !Utils.isGUID(props.routeId)) {
            enqueueSnackbar(errorRequestNotFoundText, { variant: errorSeverity, preventDuplicate: true });
        }
    }, [gridApi, props.areSelectedClientEngagementsLoaded]);

    const handleDialogClose = () => {
        setFileUploadDialogIsOpen(false);
        setUploadRestrictedFile(false);
        setFolderDialogOpen(false)
        setDeleteFileDialogOpen(false);
        setDeleteFolderDialogOpen(false);
        setUploadNewVersion(false);
        setIsFolderRename(false);
        setFolderSizeWarningDialogOpen(false);
        setFolderContentsWarningDialogOpen(false);
        setRestrictedFolderDialogOpen(false);
    };

    const getDocumentDetails = (docId: string) => {
        setDocumentTasks(null);
        setIsLoadingDocumentDetails(true);
        setSelectedDocumentDetails(null);

        const dataReadService = new DataReadService();
        docId && dataReadService.GetDocument(docId).then((response) => {
            if (response.status) {
                //@ts-ignore
                const vm: IDocument = response?.data;
                const tasks = vm?.tasks || null;
                setSelectedDocumentDetails(vm);
                tasks && setDocumentTasks(tasks);
            } else {
                let errors = [errorRetrievingHistoryText, ...response.errorMessages];
                if (gridApi?.getRowNode(docId)?.data?.isPrivate) {
                    errors = [errorFileIsRestricted];
                    handleDetailsClose();
                }
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        }).finally(() => {
            setIsLoadingDocumentDetails(false);
        })
    }

    const handleShowDetails = (rowData: any) => {
        rowData?.id && getDocumentDetails(rowData.id);
    }

    const handleEditTitle = async (data: any) => {
        if (selectedContentHierarchy) {
            const dataWriteService = new DataWriteService();

            var result = await dataWriteService.UpdateDocument(data);
            if (result.status) {
                enqueueSnackbar(successfullyUpdatedDocumentText, { variant: successSeverity });
                const newContentHierarchy: IContentHierarchy = _.cloneDeep(selectedContentHierarchy);
                newContentHierarchy.name = result.response?.data.name;
                newContentHierarchy.displayName = result.response?.data.displayName;
                addItemToHierarchy(newContentHierarchy);
                setSelectedContentHierarchy(newContentHierarchy);
                return true;
            } else {
                const errors = [errorUpdatingDocumentText, ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
                return false;
            }
        }
        else {
            enqueueSnackbar(errorUpdatingDocumentText, { variant: errorSeverity });
            return false;
        }
    }

    const handleUploadNewVersion = () => {
        setUploadNewVersion(true);
        setFileUploadDialogIsOpen(true);
    }

    const handleUpload = (restricted = false) => {
        setFileUploadDialogIsOpen(true);
        setUploadRestrictedFile(restricted);
    }

    const handleCreateNewFolder = () => {
        setFolderDialogOpen(true);
    }

    const handleRenameFolder = () => {
        setIsFolderRename(true);
        setFolderDialogOpen(true);
    }

    const handleDeleteFolderMenuClick = () => {
        setDeleteFolderDialogOpen(true);
    }

    const handleDownloadFolder = (contentHierarchyId: string, folderName: string) => {
        if (contentHierarchyId) {
            const dataReadService = new DataReadService();
            dataReadService.GetFolderSize(contentHierarchyId).then((response) => {
                if (response.status) {
                    let size: any = response.data;
                    if (size > Utils.oneHundredMB_binary) {
                        setFolderSizeWarningDialogOpen(true);
                    } else {
                        downloadFolder(contentHierarchyId, folderName);
                    }
                } else {
                    const errors = ["Error downloading folder", ...response.errorMessages];
                    Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
                }
            })
        }
    }

    const handleCreateRestrictedFolder = () => {
        setRestrictedFolderDialogOpen(true);
    }

    const enableFolderDetailsPane = () => {
        setShowDocumentDetails(false);
        setShowFolderDetails(true);
    }

    const enableDocumentDetailsPane = () => {
        setShowDocumentDetails(true);
        setShowFolderDetails(false);
    }

    const onSelectionChanged = (rowData: IContentHierarchy) => {
        const isFile = rowData.hierarchyTypeDescription === HierarchyType.File.string;

        if (isFile && requestMode === false) {
            setSelectedContentHierarchy(rowData);
            enableDocumentDetailsPane();
        }
        else {
            setDocumentTasks(null);
            setShowDocumentDetails(false);

            if (!isFile) {
                setSelectedFolder(rowData);
                !requestMode && enableFolderDetailsPane();
            }

        }
    }

    const downloadFolder = (contentHierarchyId?: string, folderName?: string) => {
        if (!contentHierarchyId && !folderName && selectedFolder) {
            contentHierarchyId = selectedFolder.id;
            folderName = selectedFolder.name;
        }
        setFolderSizeWarningDialogOpen(false);
        if (contentHierarchyId) {
            const dataReadService = new DataReadService();
            dataReadService.DownloadFolder(contentHierarchyId).then((response) => {
                if (response.status) {
                    //@ts-ignore createObjectURL expects Blob or MediaSource object.
                    //The api returns a BlobPart(superset that contains Blob) but IPlatformApiGetResult casts it to a BlobPart[]
                    let url = window.URL.createObjectURL(response.data);
                    saveAs(url, folderName + ".zip");
                } else {
                    const errors = ["Error downloading folder", ...response.errorMessages];
                    Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
                }
            })
        }
    }

    const handleDeleteFolder = async () => {
        if (selectedFolder && gridApi && hierarchy) {
            const dataWriteService = new DataWriteService();

            const fullHierarchy: IContentHierarchy[] = [];
            gridApi.forEachNode(node => fullHierarchy.push(node.data));
            const request: IDeleteFolderOrDocumentModel[] = getAllDescendentItems(selectedFolder, fullHierarchy, true)
                .map(hierarchy => {
                    return {
                        id: hierarchy.id,
                        hierarchyTypeId: hierarchy.hierarchyTypeId,
                    }
                });

            const result = await dataWriteService.DeleteFoldersOrDocuments(request);
            if (result.status) {
                removeItemAndChildrenFromHierarchy(selectedFolder);
                enqueueSnackbar(successfullyDeletedFolderText, { variant: successSeverity });
                setSelectedContentHierarchy(null);
                setShowFolderDetails(false);
            } else {
                Utils.enqueueMultiLineSnackbar([...result.errorMessages], enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }

            setDeleteFolderDialogOpen(false);
        }
    }

    const handleRestoreVersion = (docId: string, version: string) => {
        const dataWriteService = new DataWriteService();

        dataWriteService.RestoreDocumentVersion(docId, version).then((result) => {
            if (result.status) {
                enqueueSnackbar(successfullyRestoredFileText, { variant: successSeverity });
                addItemToHierarchy(result.response?.data);
                setSelectedContentHierarchy(result.response?.data);
            } else {
                const errors = [errorRestoringFileText, ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }
        })
    }

    const handleDetailsClose = () => {
        history.replace('/document-management')
        setShowDocumentDetails(false);
        setShowFolderDetails(false);
        setSelectedContentHierarchy(null);
        gridApi?.deselectAll();
    }

    const handleDeleteFile = async () => {
        if (selectedContentHierarchy) {
            const service = new DataWriteService();
            const result = await service.DeleteDocument(selectedContentHierarchy.id);

            if (result.status) {
                enqueueSnackbar(successfullyDeletedFileText, { variant: successSeverity });
                handleDialogClose();
                removeItemFromHierarchy(selectedContentHierarchy);
            } else {
                const errors = [errorDeletingFileText, ...result.errorMessages];
                Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
            }

            setShowDocumentDetails(false);
            setSelectedContentHierarchy(null);
            setDeleteFileDialogOpen(false);
        }
    }

    const addItemToHierarchy = (newItem: IContentHierarchy) => {
        if (gridApi && newItem && hierarchy) {
            const rowNode = gridApi.getRowNode(newItem.id);
            if (rowNode) {
                newItem.associatedTasks = rowNode.data?.associatedTasks;
                getParentGridDataForNewChild(newItem, gridApi);
                rowNode.setData(newItem);

                if (showDocumentDetails && selectedContentHierarchy?.id) {
                    getDocumentDetails(selectedContentHierarchy?.id!);
                }
            }
            else {
                getParentGridDataForNewChild(newItem, gridApi);
                gridApi.applyTransaction({ add: [newItem] });
            }
        }
    };

    const onFolderRenamed = (renamedFolder: IContentHierarchy) => {
        const rowNode = gridApi?.getRowNode(renamedFolder.id);
        rowNode?.setData(renamedFolder);
        const parentNode = rowNode?.parent;
        if (rowNode && parentNode) {
            const updatedNodes = getUpdatedSubtreeForUpdatedFolder(rowNode, parentNode);
            gridApi?.applyTransaction({ update: updatedNodes });
        }
        setSelectedFolder(renamedFolder);
    }

    const addItemsToHierarchy = (newItems: IContentHierarchy[]) => {
        if (gridApi && newItems.length > 0 && hierarchy) {
            const newNodes: IContentHierarchy[] = [];

            newItems.forEach((newItem) => {
                getParentGridDataForNewChild(newItem, gridApi);

                const rowNode = gridApi.getRowNode(newItem.id);
                if (rowNode) {
                    newItem.associatedTasks = rowNode.data?.associatedTasks;
                    rowNode.setData(newItem);
                } else {
                    newNodes.push(newItem);
                }

                if (uploadNewVersion === true && selectedContentHierarchy?.id) {
                    getDocumentDetails(selectedContentHierarchy?.id);
                    setSelectedContentHierarchy(rowNode?.data);
                }
            })

            if (newNodes.length) {
                gridApi.applyTransaction({ add: newNodes });
            }
        }
    }

    const removeItemAndChildrenFromHierarchy = (item: IContentHierarchy) => {
        var rowsToRemove: RowNode[] = [];

        if (gridApi) {
            const rowNode = gridApi.getRowNode(item.id);
            if (rowNode) {
                rowsToRemove = getRowsToRemove(rowNode);
                gridApi.applyTransaction({ remove: rowsToRemove });
            }
        }
    }

    const getRowsToRemove = (node: RowNode) => {
        var res: RowNode[] = [];
        for (var i = 0; i < node.childrenAfterGroup!.length; i++) {
            res = res.concat(getRowsToRemove(node.childrenAfterGroup![i]));
        }
        return node.data ? res.concat([node.data]) : res;
    }

    const removeItemFromHierarchy = (item: IContentHierarchy) => {
        if (item) {
            if (gridApi) {
                gridApi!.applyTransaction({ remove: [item] });
            }
        }
    }

    const handleRequestModeToggle = (e: any) => {
        setRequestMode(e.target.checked);
    }

    function resetColumns() {
        if (gridApi && columnApi) {
            initializeColumnState();
        }
    }

    function resetFilters() {
        if (gridApi && columnApi) {
            gridApi.setFilterModel(null);
        }
    }

    function initializeColumnState() {
        if (gridApi && columnApi) {
            columnApi.resetColumnState();
            columnApi.resetColumnGroupState();
            gridApi.sizeColumnsToFit();
        }
    }

    const exportToExcel = () => {
        setGridApi(gridApi);
        gridApi?.exportDataAsExcel();
    };

    const bulkActionWarningDialogNotRequired = (setSubmitting = true) => {
        if (searchField && !folderContentsWarningDialogOpen) {
            const folderIds = bulkActionHelper.selected.filter(bulkActionHelper.isFolderSelectable).map(folder => folder.id);
            if (folderIds.length) {
                gridApi?.getSelectedNodes().filter(rowNode => rowNode.displayed === false && folderIds.includes(rowNode.data.parentFolderId));
                setFolderContentsWarningDialogOpen(true);
                return false;
            }
        }
        return true;
    }

    const handleConfirmBulkDelete = () => {
        const onSuccessfulBulkDelete = () => {
            const folders = bulkActionHelper.selected.filter(hierarchy => bulkActionHelper.isFolderSelectable(hierarchy));
            folders.forEach(folder => removeItemAndChildrenFromHierarchy(folder));
            const folderIds = folders.map(folder => folder.id);
            bulkActionHelper.selected.forEach(hierarchy => {
                if (hierarchy.hierarchyTypeDescription === HierarchyType.File.string) {
                    const requiresRemoval = !folderIds.includes(hierarchy.parentId!);
                    requiresRemoval && removeItemFromHierarchy(hierarchy);
                }
            })
        };

        if (bulkActionWarningDialogNotRequired()) {
            handleDialogClose();
            bulkActionHelper.execute(onSuccessfulBulkDelete);

        }
    }

    const handleConfirmBulkDownload = () => {
        const downloadSizeDialogIsNotRequired = () => {
            if (!folderSizeWarningDialogOpen) {
                const fileSizeSum = [...bulkActionHelper.selected]
                    .filter(hierarchy => hierarchy.hierarchyTypeId === HierarchyType.File.id)
                    .map(hierarchy => hierarchy.fileSize)
                    .reduce((sum, currentValue) => sum + currentValue);
                if (fileSizeSum > Utils.oneHundredMB_binary) {
                    setFolderSizeWarningDialogOpen(true);
                    return false;
                }
            }
            return true;
        }

        if (bulkActionWarningDialogNotRequired() && downloadSizeDialogIsNotRequired()) {
            handleDialogClose();
            bulkActionHelper.execute();
        }
    }

    const handleConfirmBulkUpdateRestrictions = () => {
        setShowRestrictedFileAssociationDialog(true);
    }

    const onUpdateSelectedDocumentAccess = (isPrivate: boolean, usersWithAccess: string[]) => {
        const currentUserHasAccess = usersWithAccess.length ? Boolean(props.currentUser?.userId && usersWithAccess.includes(props.currentUser.userId)) : true;

        if (gridApi && selectedContentHierarchy && selectedDocumentDetails) {
            const hierarchy = _.cloneDeep(selectedContentHierarchy);
            const document = _.cloneDeep(selectedDocumentDetails);

            hierarchy.isPrivate = isPrivate;
            hierarchy.isAccessible = currentUserHasAccess;
            document.isPrivate = isPrivate;
            gridApi.applyTransaction({ update: [hierarchy] });

            setSelectedContentHierarchy(hierarchy);
            setSelectedDocumentDetails(document);
        }
        else if (bulkActionHelper.isRestrictionMode && gridApi) {
            const hierarchies = _.cloneDeep(bulkActionHelper.selected);
            hierarchies.forEach((hierarchy: IContentHierarchy) => {
                hierarchy.isPrivate = isPrivate
                hierarchy.isAccessible = currentUserHasAccess;
            });
            gridApi.applyTransaction({ update: hierarchies });
        }
    }

    const onUpdateFolderAccess = (usersWithAccess: string[]) => {
        const currentUserHasAccess = usersWithAccess.length ? Boolean(props.currentUser?.userId && usersWithAccess.includes(props.currentUser.userId)) : true;

        if (gridApi && selectedFolder) {
            const hierarchy = _.cloneDeep(selectedFolder);

            if (!currentUserHasAccess) {
                removeItemAndChildrenFromHierarchy(hierarchy);
                setSelectedFolder(null);
            }
            else {
                gridApi.applyTransaction({ update: [hierarchy] });
                setSelectedFolder(hierarchy);
            }
        }
    }

    const updateExpandAllStatus = () => {
        if (gridApi) {
            let shouldExpandAll = true;
            gridApi?.forEachNode(node => {
                const nodeIsClientFolder = node.data.hierarchyTypeDescription === HierarchyType.Client.string;
                if (!nodeIsClientFolder && hierarchyIsFolder(node.data)) {
                    shouldExpandAll = (shouldExpandAll && !node.expanded)
                }
            })
            setExpandAllFolders(shouldExpandAll);
        }
    }

    const onToggleFolderExpansion = () => {
        gridApi?.forEachNode((node: RowNode) => {
            const nodeIsClientFolder = node.data.hierarchyTypeDescription === HierarchyType.Client.string;
            gridApi.setRowNodeExpanded(node, nodeIsClientFolder || expandAllFolders);
        });
    }

    return (
        <>
            {hierarchy ?
                <>
                    <FileUploadDialog 
                        isOpen={fileUploadDialogIsOpen}
                        clientName={selectedContentHierarchy?.clientName || selectedFolder?.clientName}
                        engagementName={selectedContentHierarchy?.engagementName || selectedFolder?.engagementName}
                        fileId={uploadNewVersion === true ? selectedContentHierarchy?.id : null}
                        fileName={selectedContentHierarchy?.displayName || selectedFolder?.displayName}
                        fileExt={selectedContentHierarchy?.fileExtension}
                        uploadNewVersion={uploadNewVersion}
                        isSingleFile={false}
                        onClose={handleDialogClose}
                        onFilesUploaded={addItemsToHierarchy}
                        folderId={uploadNewVersion === true ? selectedContentHierarchy?.parentId : selectedFolder?.id}
                        uploadRestrictedFiles={uploadRestrictedFile || Boolean(!props.currentUser?.isMossAdamsStaff)}
                        withNotifications={true}
                    />
                    <FolderDialog
                        isOpen={folderDialogOpen || restrictedFolderDialogOpen}
                        clientName={selectedFolder?.clientName}
                        engagementName={selectedFolder?.engagementName}
                        parentFolderId={selectedFolder?.id!}
                        onClose={handleDialogClose}
                        onFolderCreated={addItemToHierarchy}
                        onFolderRenamed={onFolderRenamed}
                        isRename={isFolderRename}
                        currentContentHierarchy={selectedFolder}
                        isRestrictedFolder={restrictedFolderDialogOpen}
                    />
                    <BulkRestrictedFileAssociationDialog
                        open={showRestrictedFileAssociationDialog}
                        onClose={() => setShowRestrictedFileAssociationDialog(false)}
                        onUpdateAccess={onUpdateSelectedDocumentAccess}
                        bulkDocumentHelper={bulkActionHelper}
                    />
                    <ConfirmDialog
                        isOpen={deleteFileDialogOpen}
                        onClose={handleDialogClose}
                        onConfirm={handleDeleteFile}
                        title="Delete File?"
                        children={<Typography>Are you sure you wish to delete the selected file(s)?</Typography>}
                    />
                    <ConfirmDialog
                        isOpen={deleteFolderDialogOpen}
                        onClose={handleDialogClose}
                        onConfirm={handleDeleteFolder}
                        title="Delete Folder?"
                        children={<Typography>Are you sure you wish to delete this folder?
                            {selectedFolder && hierarchyIsRestrictedFolder(selectedFolder)
                                ? ' This folder is Restricted and any files contained within it will be permanently deleted.' : ''
                            }
                        </Typography>}
                    />
                    <ConfirmDialog
                        isOpen={folderSizeWarningDialogOpen}
                        onClose={handleDialogClose}
                        onConfirm={bulkActionHelper.isDownloadMode ? handleConfirmBulkDownload : () => downloadFolder()}
                        title={bulkActionHelper.isDownloadMode ? "Download Selected Files?" : "Download Folder?"}
                        children={<Typography>
                            {bulkActionHelper.isDownloadMode ?
                                `The selected files are very large and could take time to download. Do you want to proceed?`
                                : "This folder's contents are very large and could take time to download. Do you want to proceed?"}
                        </Typography>}
                    />
                    <ConfirmDialog
                        isOpen={folderContentsWarningDialogOpen}
                        onClose={handleDialogClose}
                        onConfirm={bulkActionHelper.isDeleteMode ? handleConfirmBulkDelete : handleConfirmBulkDownload}
                        title={`${_.startCase(bulkActionHelper.value!)} Selected Folders and Contents?`}
                        children={<Typography>{`You have selected at least one folder that contains files that are not displayed based on current filtering.
                             By proceeding, you are ${bulkActionHelper.isDeleteMode ? 'deleting' : 'downloading'} all files within any selected folders.`}</Typography>}
                    />
                    <Grid
                        container
                        spacing={10}
                        className={classes.root}
                    >
                        <Grid item className={classes.gridPane}>
                            <div className={classes.pageHeader}>
                                <PageHeader withoutPadding>All Documents</PageHeader>
                                <div className={classes.topControls}>
                                    <div className={classes.requestMode}>
                                        <FormControlLabel control={<Switch color="primary" onChange={handleRequestModeToggle} checked={requestMode} />} label="Request Mode" />
                                    </div>
                                    <DocumentBulkActionPopper bulkActionHelper={bulkActionHelper}
                                        onConfirmActions={{
                                            'delete': handleConfirmBulkDelete,
                                            'download': handleConfirmBulkDownload,
                                            'restrict': handleConfirmBulkUpdateRestrictions,
                                        }}
                                    />
                                    <BulkDocumentActionButtons bulkActionHelper={bulkActionHelper} />
                                    <TooltipIconButton tooltip="Export Grid to Excel" svgIcon onClick={exportToExcel}>
                                        <Icon className={classes.excelSvgIcon}></Icon>
                                    </TooltipIconButton>
                                    <Tooltip title="Reset filters">
                                        <Button size="small" color="primary" variant="contained" onClick={resetFilters} className={`${classes.topIcons} ${classes.resetButton} ${classes.svgIconButton}`} >
                                            <Icon className={classes.unfilterIcon}></Icon>
                                        </Button>
                                    </Tooltip>
                                    <LayoutManagerModalButton
                                        modalProps={{
                                            gridApi: gridApi,
                                            columnApi: columnApi,
                                            viewStorageKey: SavedViewKeys.documentManagement,
                                            onClickResetColumns: resetColumns,
                                        }}
                                    />
                                    <TooltipIconButton tooltip={expandAllFolders ? "Expand All Folders" : "Collapse All Folders"} onClick={onToggleFolderExpansion}>
                                        <ExpandMore style={expandAllFolders ? { transform: 'rotate(-90deg)' } : {}} />
                                    </TooltipIconButton>
                                    <SearchIcon className={classes.topIcons} />
                                    <div>
                                        <FormControl>
                                            <Typography variant="subtitle1">
                                                <TextField
                                                    label="Search"
                                                    name="Search"
                                                    type="search"
                                                    value={searchField}
                                                    onChange={(e: any) => {
                                                        setSearchField(e.target.value);
                                                    }}>
                                                </TextField>
                                            </Typography>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <ConditionalVisiblity classes={classes.gridSpan} useLoadingIndicator isVisible={!isLoading}>
                                <DocumentGrid
                                    documentPageState={{
                                        hierarchy,
                                        gridApi,
                                        columnApi,
                                        bulkActionHelper,
                                        requestMode,
                                    }}
                                    folderContextMenuActions={{
                                        handleCreateNewFolder,
                                        handleRenameFolder,
                                        handleDeleteFolderMenuClick,
                                        handleUpload,
                                        handleDownloadFolder,
                                        handleCreateRestrictedFolder,
                                    }}
                                    fileContextMenuActions={{
                                        handleUploadNewVersion,
                                        handleDeleteFile: () => setDeleteFileDialogOpen(true),
                                    }}
                                    onSelectionChanged={onSelectionChanged}
                                    onDocumentsGridReady={(params: GridReadyEvent) => {
                                        setGridApi(params.api);
                                        setColumnApi(params.columnApi);
                                    }}
                                    onFolderContextMenuInitiated={(hierarchy: IContentHierarchy) => setSelectedFolder(hierarchy)}
                                    onHierarchyMoved={(hierarchy: IContentHierarchy) => {
                                        if (selectedContentHierarchy?.id === hierarchy.id) {
                                            getDocumentDetails(selectedContentHierarchy?.id);
                                        }
                                    }}
                                    onNewItemsAdded={addItemsToHierarchy}
                                    onChangeFolderExpansionState={updateExpandAllStatus}
                                />
                            </ConditionalVisiblity>
                        </Grid>

                        <DetailsPane renderPane={!requestMode && showDocumentDetails && Boolean(selectedContentHierarchy)}>
                            <Route exact path="/document-management/:id">
                                <DocumentDetails
                                    key={`${selectedDocumentDetails?.documentGuid}`}
                                    onClose={handleDetailsClose}
                                    onEdit={handleEditTitle}
                                    documentTasks={documentTasks}
                                    isLoadingDetails={isLoadingDocumentDetails}
                                    selectedDocument={selectedDocumentDetails}
                                    contentHierarchy={selectedContentHierarchy}
                                    documentId={selectedContentHierarchy?.id!}
                                    documentName={selectedContentHierarchy?.displayName!}
                                    onUploadNewVersion={handleUploadNewVersion}
                                    onRestoreVersion={handleRestoreVersion}
                                    onDocumentDelete={() => { setDeleteFileDialogOpen(true); }}
                                    onUpdateAccess={onUpdateSelectedDocumentAccess}
                                />
                            </Route>
                        </DetailsPane>
                        <DetailsPane renderPane={!requestMode && showFolderDetails && Boolean(selectedFolder)}>
                            <Route exact path="/document-management/folder/:id">
                                <FolderDetails
                                    key={`folderDetails-${selectedFolder?.id}`}
                                    onClose={() => setShowFolderDetails(false)}
                                    folder={selectedFolder}
                                    onUpdateAccess={onUpdateFolderAccess}
                                    downloadFolder={handleDownloadFolder}
                                    deleteFolder={handleDeleteFolderMenuClick}
                                />
                            </Route>
                        </DetailsPane>



                        {(requestMode === true && selectedFolder?.engagementId) &&
                            <Grid item className={classes.requestsPane}>
                                <TaskListDnd engagementId={selectedFolder?.engagementId} documentGridApi={gridApi} engagementName={selectedFolder?.engagementName} />
                            </Grid>
                        }
                        {(requestMode === true && !selectedFolder?.engagementId) &&
                            <Grid item className={classes.requestsPane}>
                                <Typography>Select a folder to see the requests list.</Typography>
                            </Grid>
                        }
                    </Grid>
                </>
                :
                <>
                    {(selectedClientAndEngagementIds.length > 0 && props.areSelectedClientEngagementsLoaded) && isLoading ?
                        <LoadingIndicator />
                        :
                        !errorLoadingHierarchy && <Typography variant="h4">Please select a client in the header above to get started. </Typography>
                    }
                </>
            }
        </>
    );
};

export default DocumentManagement;
