import React from 'react';
import { useSnackbar } from "notistack";
import IContentHierarchy from "../../interfaces/IContentHierarchy";
import ITaskDocument from "../../interfaces/ITaskDocument";
import { CustomDropzone } from "../CustomDropzone/CustomDropzone";
import { errorSeverity } from "../FileUploadDialog/FileUploadDialog";
import ITask from '../../interfaces/ITask';
import { makeStyles } from "@material-ui/core/styles";
import { useFileUploadContext } from '../../contexts/FileUploadContext/FileUploadContext';
import IPlatformApiPostResult from '../../interfaces/IPlatformApiPostResult';
import { createUploadParams } from '../../contexts/FileUploadContext/UploadParams';
import { SnackbarVariantTypes } from '../../helpers/enums/enums';
import { Utils } from '../../utilities/utils';
import { successfullyAttachedFilesText } from './AttachmentsGrid';

const useStyles = makeStyles((theme) => ({
    dropzoneDisabled: {
        height: "100%"
    },
    backdrop: {
        zIndex: 100,
        color: '#fff',
    },
}));

interface TaskDocumentsDropzoneProps {
    task: ITask;
    taskDocuments: ITaskDocument[];
    uploadFolderId: string;
    children: React.ReactNode;
    fileUploadDialogIsOpen?: boolean;
    disabled: boolean;
    onAttachmentsCreated: (attachedTaskDocuments: ITaskDocument[]) => void;
    onAttachment?: (result: IPlatformApiPostResult) => void
}

export const TaskDocumentsDropzone = (props: TaskDocumentsDropzoneProps) => {
    const { taskDocuments, task, uploadFolderId, children, onAttachmentsCreated, onAttachment } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const uploadContext = useFileUploadContext();
    
    const uploadSuccessCallback = (result: IPlatformApiPostResult) => {
        const newTaskDocuments = result.response?.data?.createdTaskDocuments as ITaskDocument[];
        onAttachmentsCreated(newTaskDocuments);
    }

    const handleAttachmentGridDrop = async (files: File[], handleFinishedLoad: Function) => {
        const hasAttachment = taskDocuments.length > 0;
        const uploadingMultiple = files.length > 1;
        if (!task.allowMultipleAttachments && (hasAttachment || uploadingMultiple)) {
            var fileCountError = "There can only be one file for this task."
            enqueueSnackbar(fileCountError, { variant: errorSeverity });
            handleFinishedLoad();
            throw fileCountError;
        }

        uploadContext.handleFileChange(files);

        const uploadParams = createUploadParams()
            .withFolderId(uploadFolderId)
            .withNotifications(false)
            .withRequestAttachment(props.task)
            .onSuccess(uploadSuccessCallback)
            .build();
        uploadContext.initiateUpload(uploadParams);

        handleFinishedLoad();
    }

    return (
        <div>
            {props.disabled ? <div className={classes.dropzoneDisabled}>{children}</div> :
                <CustomDropzone
                    handleDrop={handleAttachmentGridDrop}
                    fileUploadDialogIsOpen={props.fileUploadDialogIsOpen}
                >
                    {children}
                </CustomDropzone>
            }
        </div>
    );
}


export const useOnRequestAttachmentsCreated = ({isRestrictedTask, onAttachmentsCreated}: any) => {
    const { enqueueSnackbar } = useSnackbar();

    const onRequestAttachmentsCreated = (result: IPlatformApiPostResult) => {
        const newTaskDocuments = result.response?.data?.createdTaskDocuments as ITaskDocument[];

        if (result.status && newTaskDocuments) {
            const restrictedRequestAttachment = "The file(s) uploaded successfully and only the Assignee(s) of the request have access.";
            const successMessage = isRestrictedTask ? restrictedRequestAttachment : successfullyAttachedFilesText;
            enqueueSnackbar(successMessage, { variant: SnackbarVariantTypes.Success });

            onAttachmentsCreated(newTaskDocuments);
        }
        else {
            const errors = ["Failed to attach file to request.", ...result.errorMessages];
            Utils.enqueueMultiLineSnackbar(errors, enqueueSnackbar, { variant: SnackbarVariantTypes.Error });
        }
    }

    return onRequestAttachmentsCreated;
} 