import React from 'react'
import { useFileUpload } from './hooks/useFileUpload';
import IDocumentsUploadModel from '../../interfaces/IDocumentsUploadModel';
import { FileUploadContext } from './FileUploadContext';
import { useGetFileCollisions } from './hooks/useGetFileCollisions';
import ProgressSpinnerBackdrop from '../../components/common/ProgressSpinnerBackdrop/ProgressSpinnerBackdrop';
import SpinnerBackdrop from '../../components/common/SpinnerBackdrop';
import { defaultUploadParams, UploadParams } from './UploadParams';
import _ from 'lodash';

export interface FileUploadContextProviderProps {
    children: React.ReactNode;
}

export const FileUploadContextProvider = ({ children }: FileUploadContextProviderProps) => {
    const [fileNameCollisions, setFileNameCollisions] = React.useState<File[]>([]);
    const [uploadParams, setUploadParams] = React.useState<UploadParams>(defaultUploadParams);
    const { 
        folderId, 
        newVersionDocumentId, 
        isRestricted, 
        suppressNotifications, 
        permittedUsers,
        task
    } = uploadParams;

    const {
        files,
        updateSelectedFiles,
        handleUpload,
        uploadProgress,
        isUploading,
        uploadResult,
    } = useFileUpload();

    const { getDuplicateFiles, loadingNameCollisions } = useGetFileCollisions();
    
    React.useEffect(() => {
        if (canUploadFiles()) {
            uploadFiles() 
        } 
    },[uploadParams])

    const canUploadFiles = () => {
        return files.length && !_.isEqual(defaultUploadParams, uploadParams);
    }

    const onAfterUploadAttempted = () => {
        if (uploadResult) {
            if (uploadResult.status) {
                uploadParams.uploadSuccessCallback(uploadResult);
            }
            else {
                uploadParams.uploadFailureCallback(uploadResult);
            }
            onUploadEnded();
        }
    }

    React.useEffect(onAfterUploadAttempted, [uploadResult])

    const checkForFileNameCollisions = async () => {
        setFileNameCollisions([]);

        const nameCollisions = newVersionDocumentId === null
            ? await getDuplicateFiles(files, folderId)
            : [];
        if (nameCollisions === undefined) {
            return;
        }
        setFileNameCollisions(nameCollisions);
        return nameCollisions;
    }

    const getUploadModel = (overwriteExisting: boolean = true) => {
        const model: IDocumentsUploadModel = {
            folderId: folderId,
            newVersionDocumentId: newVersionDocumentId,
            documentFiles: [],
            overwriteExisting: overwriteExisting,
            isPrivate: Boolean(isRestricted),
            suppressNotifications: Boolean(suppressNotifications),
            permittedUsers: permittedUsers,
            task: task
        }
        return model;
    }

    const handleRemoveFile = (fileToRemove: File) => {
        const remainingFiles = files.filter((file) => file.name !== fileToRemove.name);
        updateSelectedFiles(remainingFiles);
    }

    const uploadFiles = async () => {
        const nameCollisions = await checkForFileNameCollisions();

        if (nameCollisions && !nameCollisions?.length) {
            await handleUpload(getUploadModel(),);
        }
    }

    const uploadNewVersionOnCollision = async () => {
        if (canUploadFiles() && fileNameCollisions.length) {
            await handleUpload(getUploadModel())
        }
    }

    const uploadWithRenameOnCollision = async () => {
        if (canUploadFiles() && fileNameCollisions.length) {
            await handleUpload(getUploadModel(false))
        }
    }

    const initiateUpload = async (params: UploadParams) => {
        setUploadParams(params);
    }

    const cancelUpload = () => {
        if (canUploadFiles()) {
            onUploadEnded();
        }
    }

    const onUploadEnded = () => {
        uploadParams.onEndedCallback();
        setUploadParams(defaultUploadParams);
        setFileNameCollisions([]);
    }

    const uploadingBackdrop = isUploading ? <ProgressSpinnerBackdrop key={`uploadingBackdrop`} progress={uploadProgress} isUploading={isUploading} />
        : <SpinnerBackdrop isActive={loadingNameCollisions} />

    const value: FileUploadContext = {
        files,
        fileNameCollisions,
        handleFileChange: updateSelectedFiles,
        handleRemoveFile,
        initiateUpload,
        cancelUpload,
        uploadNewVersionOnCollision,
        uploadWithRenameOnCollision,
        uploadingBackdrop,
    }

    return <FileUploadContext.Provider value={value}>
        {children}
    </FileUploadContext.Provider>
}