import { Grid, Typography, makeStyles } from '@material-ui/core';
import { LocalDateTimeString } from '../../helpers/DateHelpers';
import IContentHierarchy from '../../interfaces/IContentHierarchy';
import ExpirationWarningIcon from '../ExpirationWarningIcon/ExpirationWarningIcon';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../interfaces/ICurrentUser';
import _ from 'lodash';

interface Props {
    document: IContentHierarchy;
}

const useStyles = makeStyles((theme) => {
    return {
        expirationDate: {
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    }
});

function DocumentExpirationDate(props: Props) {
    const classes = useStyles();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    if (!currentUser.isMossAdamsStaff) {
        return null;
    }

    const renderDate = props.document.expirationDate;
    return (
        <Grid item container className={classes.expirationDate}>
            <span>
                <Typography>
                    Expires: {LocalDateTimeString(renderDate, false)} <ExpirationWarningIcon date={renderDate} />
                </Typography>
            </span>
        </Grid>
    )
}

export default DocumentExpirationDate