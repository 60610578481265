import React from 'react'
import { DeleteSweep, GetApp, Lock, Timer } from '@material-ui/icons';
import { BulkDocumentActionOption, IBulkDocumentActionHelper } from '../useDocumentsBulkActionHelper';
import TooltipIconButton from '../../common/TooltipIconButton/TooltipIconButton';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../../interfaces/ICurrentUser';

interface Props {
    bulkActionHelper: IBulkDocumentActionHelper;
}

export default function BulkDocumentActionButtons({ bulkActionHelper }: Props) {
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);

    const getOnClick = (bulkActionMode: BulkDocumentActionOption) => {
        return bulkActionHelper.isActive
            ? undefined
            : (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => bulkActionHelper.onToggleBulkAction(e, bulkActionMode);
    }

    const buttons = [
        {
            tooltip: 'Bulk Manage Access',
            icon: <Lock />,
            onClick: getOnClick('restrict'),
            key: 1,
            shouldRender: currentUser.isMossAdamsStaff
        },
        {
            tooltip: 'Download',
            icon: <GetApp />,
            onClick: getOnClick('download'),
            key: 2
        },
        {
            tooltip: 'Delete',
            icon: <DeleteSweep />,
            onClick: getOnClick('delete'),
            key: 3,
        },
    ]

    return <>
        {
            buttons.map(buttonConfig => 
                buttonConfig.shouldRender === false ? null 
                : <TooltipIconButton tooltip={buttonConfig.tooltip}
                    key={buttonConfig.key}
                    onClick={buttonConfig.onClick}>
                    {buttonConfig.icon}
                </TooltipIconButton>
            )
        }
    </>
}