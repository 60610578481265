import React, { FunctionComponent } from 'react';
import IDownloadFileProps from "./IDownloadFileProps";
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { actionButtonVariant } from '../../helpers/portalTheme';
import { useDownloadFile } from '../../hooks/useDownloadFile';

const useStyles = makeStyles((theme) => ({
    actionButton: {
        ...actionButtonVariant,
    },
}));

const DownloadFile: FunctionComponent<IDownloadFileProps> = ({ documentId, fileName, fileVersion, children }) => {
    const classes = useStyles();

    const { handleDownload, downloadingFile } = useDownloadFile();
    const downloadFile = () => handleDownload(documentId, fileName, fileVersion);

    const spinner = <CircularProgress size={24} color="primary" />;
    return <>
        {Boolean(children)
            ? <span onClick={downloadFile}>{downloadingFile ? spinner : children}</span>
            : <Button
                className={classes.actionButton}
                title="Download File"
                onClick={downloadFile}
                color="primary"
                disabled={downloadingFile}
                startIcon={downloadingFile ? null : <GetAppIcon />}
            >
                {downloadingFile ? <Box mb={-4} ml={-2}>{spinner}</Box> : null}
            </Button>
        }
    </>
}

export default DownloadFile;