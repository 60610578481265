import React from 'react'
import { FileUploadProviderWrapper } from '../../contexts/FileUploadContext/FileUploadProviderWrapper'
import EngagementManagement, { IEngagementManagementProps } from './EngagementManagement'
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../interfaces/ICurrentUser';

interface RouteParams {
    id: string;
}

export default function EngagementManagementContainer() {
    const { id } = useParams<RouteParams>();

    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);
    const areCurrentClientEngagementsLoaded = useSelector((state: any) => state.areSelectedClientEngagementsLoaded as boolean);
    const selectedClients = useSelector((state: any) => state.selectedClients as string[] | undefined);
    const selectedEngagements = useSelector((state: any) => state.selectedEngagements as string[] | undefined);
    const engagementManagementIsDirty = useSelector((state: any) => state.engagementManagementIsDirty as boolean);

    const props: IEngagementManagementProps = {
        currentUser,
        areSelectedClientEngagementsLoaded: areCurrentClientEngagementsLoaded,
        selectedClients: selectedClients,
        selectedEngagements: selectedEngagements,
        isDirty: engagementManagementIsDirty,
        routeId: id,
    }

    return (
        <FileUploadProviderWrapper>
            <EngagementManagement {...props} />
        </FileUploadProviderWrapper>
    )
}
