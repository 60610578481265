import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import {
    AllModules, CellFocusedEvent, GetContextMenuItemsParams, GridReadyEvent, ICellRendererParams,
    RowClassParams, RowClickedEvent, RowNode, SelectionChangedEvent, ValueGetterParams, MenuItemDef, GridApi, ColumnApi, RowDragMoveEvent, RowDragLeaveEvent, RowDragEndEvent, RefreshCellsParams, RedrawRowsParams, ColDef
} from '@ag-grid-enterprise/all-modules';
import React, { useCallback } from 'react'
import { associatedTasksComparator } from '../../../helpers/Comparators';
import IContentHierarchy from '../../../interfaces/IContentHierarchy';
import { Utils } from '../../../utilities/utils';
import { LocalDateTimeString } from '../../../helpers/DateHelpers';
import { HierarchyType } from '../../../helpers/enums/HierarchyType';
import { DateTime } from 'luxon';
import { EllipsisButtonActionRenderer } from '../../AgGridRenderers/EllipsisButtonActionRenderer';
import AssociatedRequestsCellRenderer from '../../AgGridRenderers/AssociatedRequestsCellRenderer';
import portalTheme from '../../../helpers/portalTheme';
import { PrivateHierarchyCellRenderer } from '../../AgGridRenderers/PrivateHierarchyCellRenderer';
import _ from 'lodash';
import { CustomDropzone } from '../../CustomDropzone/CustomDropzone';
import FolderIcon from "@material-ui/icons/Folder";
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from '@material-ui/core';
import { DocumentGridProps } from './DocumentGridProps';
import { dateRenderer } from "../../engagementManagement/RequestCellRenderers";
import DocumentExpirationDateCellRenderer from '../../AgGridRenderers/DocumentExpirationDateCellRenderer';
import { useSelector } from 'react-redux';
import ICurrentUser from '../../../interfaces/ICurrentUser';
import { ExpireDateFilter } from '../../AgGridFilters/ExpireDateFilter';
import { getContentHierarchyUrl, hierarchyIsFile, hierarchyIsRestrictedFolder } from '../../../helpers/ContentHierarchyHelpers';
import { useHistory } from 'react-router-dom';
import RestrictedIcon from '../../common/RestrictedIcon/RestrictedIcon';
import { useMoveContentHierarchy } from './useMoveContentHierarchies';
import SpinnerBackdrop from '../../common/SpinnerBackdrop';
import { ConfirmDialog } from '../../ConfirmDialog/ConfirmDialog';
import { useDownloadFile } from '../../../hooks/useDownloadFile';
import { useCopyLinkToClipboard } from '../../../hooks/useCopyLinkToClipboard';
import { useFileUploadContext } from '../../../contexts/FileUploadContext/FileUploadContext';
import { createUploadParams } from '../../../contexts/FileUploadContext/UploadParams';
import IPlatformApiPostResult from '../../../interfaces/IPlatformApiPostResult';

const useStyles = makeStyles((theme) => ({
    cellIcon: {
        verticalAlign: "middle",
        marginRight: "10px",
        color: theme.palette.primary.main,
        height: "26px",
        width: "auto"
    },
    clientRow: {
        "&&.ag-row": {
            backgroundColor: portalTheme.palette.mossadams.darkGreen600,
            color: portalTheme.palette.common.white,

            "&& .ag-icon": {
                color: portalTheme.palette.common.white
            },
            "&& .MuiSvgIcon-root": {
                color: portalTheme.palette.common.white
            },
            "& div[col-id='ag-Grid-AutoColumn']": {
                visibility: "hidden",
            },
        }
    },
    engagementRow: {
        "&&.ag-row": {
            backgroundColor: portalTheme.palette.mossadams.darkGreen400,
            color: portalTheme.palette.common.white,

            "&& .ag-icon": {
                color: portalTheme.palette.common.white
            },
            "&& .MuiSvgIcon-root": {
                color: portalTheme.palette.common.white
            }
        }
    },
    folderRow: {
        "&&.ag-row": {
            backgroundColor: portalTheme.palette.mossadams.darkGreen200,
        }
    },
    rightAlignedGridCell: {
        textAlign: "right"
    },
    documentGrid: {
        height: "100%",
        '& .ag-cell-value': {
            flexGrow: '1',
        },
        '& .ag-group-value': {
            flexGrow: '1',
        },
        '& .custom-checkbox': {
            width: '18px',
            height: '18px',
            verticalAlign: 'middle',
            accentColor: portalTheme.palette.primary.main,
        },
    },
    paperTableRoot: {
        "& .MuiPaper-root": {
            height: "-webkit-fill-available",
        },
        height: '100%',
    },
    hoverOver: {
        backgroundColor: portalTheme.palette.secondary.main,

        "&&.ag-row": {
            backgroundColor: portalTheme.palette.secondary.main
        }
    }
}));

export default function DocumentGrid(props: DocumentGridProps) {
    const {
        onFolderContextMenuInitiated,
        onDocumentsGridReady,
        onNewItemsAdded,
    } = props;
    const {
        bulkActionHelper,
        hierarchy,
        gridApi,
        requestMode,
    } = props.documentPageState;

    const classes = useStyles();
    const [showConfirmMoveModal, setShowConfirmMoveModal] = React.useState(false);
    const [moveConfirmationMessage, setMoveConfirmationMessage] = React.useState<string>('');

    const folderIdToIsUploadingRefMap = React.useRef(new Map<string, boolean>());
    const confirmMovePromiseRef = React.useRef<(value: boolean | PromiseLike<boolean>) => void>();
    const currentUser = useSelector((state: any) => state.currentUser as ICurrentUser);
    const history = useHistory();
    const { handleDownload, downloadingFile } = useDownloadFile();
    const { getHierarchyLink } = useCopyLinkToClipboard();
    const uploadContext = useFileUploadContext();

    React.useEffect(() => {
        gridApi?.redrawRows();
    }, [requestMode])

    const initializeGrid = (gridApi: GridApi, columnApi: ColumnApi) => {
        if (gridApi && columnApi) {
            gridApi.setFilterModel(null);
            columnApi.resetColumnState();
            columnApi.resetColumnGroupState();
            gridApi.sizeColumnsToFit();
        }
    }

    const onGridReady = (params: GridReadyEvent) => {
        initializeGrid(params.api, params.columnApi);
        onDocumentsGridReady(params);
    }

    const onRequestMoveConfirmation = (message: string) => {
        setMoveConfirmationMessage(message);
        setShowConfirmMoveModal(true);
        return new Promise<boolean>((resolve, reject) => {
            confirmMovePromiseRef.current = resolve;
        })
    }

    const onConfirmMove = () => {
        setShowConfirmMoveModal(false);
        confirmMovePromiseRef.current && confirmMovePromiseRef?.current(true);
    }

    const onCancelMove = () => {
        setShowConfirmMoveModal(false);
        confirmMovePromiseRef.current && confirmMovePromiseRef?.current(false);
    }

    const { potentialParent,
        onRowDragMove,
        onRowDragLeave,
        onRowDragEnd,
        loading: movingItem
    } = useMoveContentHierarchy(gridApi, onRequestMoveConfirmation, props.onHierarchyMoved);

    const handleCellFocused = (event: CellFocusedEvent) => {
        const suppress = bulkActionHelper.isActive && event.column?.getColId() === '...';
        event.api.setSuppressRowClickSelection(suppress);
    }

    const hierarchyNameSort = (a: string, b: string, nodeA: any, nodeB: any, inverted: boolean = false) => {
        if (!nodeA?.data || !nodeB?.data) { return 0; }
        return ((nodeB.data.hierarchyTypeDescription === HierarchyType.File.string ? 0 : 1) -
            (nodeA.data.hierarchyTypeDescription === HierarchyType.File.string ? 0 : 1))
            || + (a.toLowerCase() > b.toLowerCase())
            || - (a.toLowerCase() < b.toLowerCase())
    }

    const handleFileDrop = (files: File[], rowData: IContentHierarchy, onNewItemsAdded: Function) => {
        folderIdToIsUploadingRefMap.current.set(rowData.id, true);
        uploadContext.handleFileChange(files);

        const onUploadSuccess = (result: IPlatformApiPostResult) => {         
            const items = result?.response?.data as IContentHierarchy[];   
            onNewItemsAdded(items);
        }
    
        const uploadParams = createUploadParams()
            .withFolderId(rowData.id)
            .withNotifications(true)
            .withRestriction(!currentUser.isMossAdamsStaff)
            .onSuccess(onUploadSuccess)
            .onEnded(() => onUploadCompleted(rowData))
            .build();
            
        uploadContext.initiateUpload(uploadParams);
    }

    const onUploadCompleted = (rowData: IContentHierarchy) => {
        folderIdToIsUploadingRefMap.current.set(rowData.id, false);
    }

    const TreeCellRenderer = (props: ICellRendererParams) => {
        const rowData: IContentHierarchy = props.data;
        const selected: IContentHierarchy[] = props.context.selected;
        const bulkActionIsActive: boolean = props.context.bulkActionIsActive;
        const checkbox = (bulkActionIsActive && props.context.isRowSelectable(rowData))
            ? <input type='checkbox' className='custom-checkbox' readOnly
                checked={selected.filter(hierarchy => hierarchy.id === rowData.id).length > 0}
            />
            : null

        switch (rowData.hierarchyTypeDescription) {
            case HierarchyType.Client.string:
                return (<>{rowData.displayName}</>);
            case HierarchyType.Engagement.string:
            case HierarchyType.SystemFolder.string:
            case HierarchyType.CustomFolder.string:
            case HierarchyType.SecureFolder.string:
            case HierarchyType.RestrictedFolder.string:
                let isUploading = folderIdToIsUploadingRefMap.current.get(rowData.id);
                if (!isUploading)
                    folderIdToIsUploadingRefMap.current.set(rowData.id, false);
                return <CustomDropzone
                    loading={Boolean(isUploading)}
                    handleDrop={(files: File[]) => handleFileDrop(files, rowData, props.context.onNewItemsAdded)}
                >
                    {checkbox}
                    <FolderIcon className={classes.cellIcon} />{rowData.displayName}
                </CustomDropzone>;
            case HierarchyType.File.string:
                return (<>{checkbox}<DescriptionIcon className={classes.cellIcon} />{rowData.displayName}</>);
        }
    }

    const UploadedByRenderer = (props: ICellRendererParams) => {
        const rowData: IContentHierarchy = props.data;
        if (rowData?.hierarchyTypeDescription === HierarchyType.File.string) {
            return <>{rowData.uploadedByUserName ?? ''}</>;
        }
        return <></>
    }

    const deselectAncestorFolders = (rowNode: RowNode | null | undefined) => {
        const parent = rowNode?.parent;
        if (!bulkActionHelper.isFolderSelectable(parent?.data))
            return;
        if (parent && parent.isSelected())
            parent.setSelected(false, false, true);
        if (parent?.parent)
            deselectAncestorFolders(parent);
    }

    const handleRowSelected = (event: RowClickedEvent) => {
        const { data, node, api } = event;

        if (bulkActionHelper.isActive) {
            const selectableNode = bulkActionHelper.isRowSelectable(data);
            if (!selectableNode) {
                node.setSelected(false, false, true);
                return;
            }

            if (bulkActionHelper.isFolderSelectable(data)) {
                const isSelected = !!node.isSelected();
                node.allLeafChildren.forEach(rowNode => rowNode.setSelected(isSelected, false, true));
            }
            if (node.parent && !node.isSelected() && node.parent.isSelected()) {
                deselectAncestorFolders(node);
            }
            const selectedHierarchies: IContentHierarchy[] = api.getSelectedNodes().map(node => node.data);

            bulkActionHelper.setSelectedForBulkAction([...selectedHierarchies]);
        }
    }

    const handleSelectionChanged = (event: SelectionChangedEvent) => {
        const selectedRows = event.api.getSelectedRows();

        if (!bulkActionHelper.isActive && selectedRows && selectedRows.length > 0) {
            const rowData = selectedRows[0];
            history.replace(getContentHierarchyUrl(rowData));
            props.onSelectionChanged(rowData);
        }
        else if (bulkActionHelper.isActive && selectedRows?.length) {
            const invalidSelections: IContentHierarchy[] = [];
            selectedRows.forEach((hierarchy: IContentHierarchy) => {
                !bulkActionHelper.isRowSelectable(hierarchy) && invalidSelections.push(hierarchy);
            });
            invalidSelections.forEach(hierarchy => {
                const rowNode = event.api.getRowNode(hierarchy.id);
                rowNode?.setSelected(false, false, true);
            });
        }
    }

    const getContextMenuItems = (params: GetContextMenuItemsParams) => {
        let menuItems: MenuItemDef[] = [];
        const data: IContentHierarchy | null = params.node?.data;
        if (!data) return menuItems;

        if (hierarchyIsFile(data)) {
            menuItems = getFileContextMenuItems(data);
        }
        else {
            menuItems = getFolderContextMenuItems(data);
        }

        return menuItems;
    }

    const getFileContextMenuItems = (data: IContentHierarchy) : MenuItemDef[] => {
        const menuItems: (MenuItemDef|null)[] = [
            {
                name: "Download File",
                action: () => handleDownload(data.id, data.displayName)
            },
            {
                name: "Copy File Link",
                action: () => getHierarchyLink(data)
            },
            {
                name: "Upload New Version",
                action: () => props.fileContextMenuActions.handleUploadNewVersion(),
            },
            {
                name: "Delete File",
                action: () => props.fileContextMenuActions.handleDeleteFile()
            },
        ]

        return menuItems.filter(Boolean) as MenuItemDef[];
    }

    const getFolderContextMenuItems = (data: IContentHierarchy) => {
        const menuItems: MenuItemDef[] = [];

        const actions = props.folderContextMenuActions;
        onFolderContextMenuInitiated(data);

        const createFolderAction = {
            name: "Create New Folder",
            action: () => currentUser.isMossAdamsStaff ? actions.handleCreateNewFolder() : actions.handleCreateRestrictedFolder()
        }

        const uploadAction = {
            name: "Upload New File",
            action: actions.handleUpload
        }

        const uploadRestrictedAction = {
            name: "Upload Restricted File",
            action: () => actions.handleUpload(true)
        }

        const renameFolderAction = {
            name: "Rename Folder",
            action: actions.handleRenameFolder
        }

        const downloadFolderAction = {
            name: "Download All Files",
            action: () => actions.handleDownloadFolder(data.id, data.name)
        }

        const deleteFolderAction = {
            name: "Delete Folder",
            action: actions.handleDeleteFolderMenuClick
        }

        const createRestrictedFolderAction = {
            name: "Create New Restricted Folder",
            action: actions.handleCreateRestrictedFolder
        }

         const hType = data.hierarchyTypeDescription;
        const isMossAdamsStaff = currentUser.isMossAdamsStaff;
        const isSystemFolder = hType === HierarchyType.SystemFolder.string;
        const isCustomFolder = hType === HierarchyType.CustomFolder.string;
        const isRestrictedFolder = hierarchyIsRestrictedFolder(data);
        const isClientDocsOrInitialFileTransferFolder = isSystemFolder && (data.name === "Client Documents" || data.name === "Initial File Transfer");
        
        if (hType === HierarchyType.Client.string) {
            if (isMossAdamsStaff) {
                menuItems.push(createFolderAction);
                menuItems.push(createRestrictedFolderAction);
            }
        }
        
        if (hType === HierarchyType.Engagement.string || isSystemFolder || isCustomFolder || hType === HierarchyType.SecureFolder.string) {
            menuItems.push(uploadAction);
            if (isMossAdamsStaff) {
                menuItems.push(uploadRestrictedAction);
            }
            menuItems.push(downloadFolderAction);
            if (isMossAdamsStaff) {
                menuItems.push(createFolderAction);
                menuItems.push(createRestrictedFolderAction);
            } else if (isClientDocsOrInitialFileTransferFolder) {
                menuItems.push(createFolderAction);
            }
        }
        
        if (isRestrictedFolder) {
            menuItems.push(uploadAction);
            menuItems.push(downloadFolderAction);
        }
        
        if (isMossAdamsStaff && (isCustomFolder || isRestrictedFolder)) {
            menuItems.push(renameFolderAction);
            menuItems.push(deleteFolderAction);
        }
        
        return menuItems;
    }

    const dateFormatter = (value: Date) => {
        return LocalDateTimeString(value, false);
    }

    const getIndentClass = (params: any) => {
        var indent = 0;
        var node = params.node;
        while (node && node.parent) {
            indent++;
            node = node.parent;
        }
        return 'indent-' + indent;
    }

    const defaultExcelExportParams = {
        fileName: "DocumentList" + "_" + DateTime.now().toFormat('MM-dd-yyyy'),
        sheetName: "Documents"
    };

    const excelStyles = [
        {
            id: 'indent-1',
            indent: 1,
        }
    ]

    const rowClassRules = {
        [classes.clientRow]: (rowClassParams: RowClassParams) => {
            return rowClassParams.data?.hierarchyTypeDescription === HierarchyType.Client.string
        },
        [classes.engagementRow]: (rowClassParams: RowClassParams) => {
            return rowClassParams.data?.hierarchyTypeDescription === HierarchyType.Engagement.string
        },
        [classes.folderRow]: (rowClassParams: RowClassParams) => {
            return rowClassParams.data?.hierarchyTypeDescription === HierarchyType.CustomFolder.string
                || rowClassParams.data?.hierarchyTypeDescription === HierarchyType.SystemFolder.string
                || rowClassParams.data?.hierarchyTypeDescription === HierarchyType.SecureFolder.string
                || rowClassParams.data?.hierarchyTypeDescription === HierarchyType.RestrictedFolder.string
        },
    }

    const cellClassRules = React.useMemo(() => {
        return {
            [classes.hoverOver]: (rowClassParams: RowClassParams) => {
                return Boolean(potentialParent) && rowClassParams.node.id === potentialParent?.id;
            }
        }
    }, [potentialParent])

    const components = {
        actionsRenderer: EllipsisButtonActionRenderer,
        treeCellRenderer: TreeCellRenderer,
        privateFileCellRenderer: PrivateHierarchyCellRenderer,
        uploadedByRenderer: UploadedByRenderer,
        dateRenderer: dateRenderer,
        requestNumberCellRenderer: AssociatedRequestsCellRenderer,
        expirationDateCellRenderer: DocumentExpirationDateCellRenderer,
    }

    return (
        <Paper className={classes.paperTableRoot}>
            <SpinnerBackdrop isActive={movingItem || downloadingFile} />
            <ConfirmDialog
                isOpen={showConfirmMoveModal}
                onConfirm={onConfirmMove}
                onClose={onCancelMove}
                title={'File Restriction Change'}
                children={<Typography>{moveConfirmationMessage}</Typography>}
            />
            <div className={"ag-theme-alpine " + classes.documentGrid}>
                <AgGridReact
                    rowData={hierarchy}
                    defaultExcelExportParams={defaultExcelExportParams}
                    excelStyles={excelStyles}
                    rowClassRules={rowClassRules}
                    onGridReady={onGridReady}
                    onSelectionChanged={_.debounce(handleSelectionChanged, 1000)}
                    getContextMenuItems={getContextMenuItems}
                    onCellClicked={(params: any) => {
                        if (params.column.colDef.field === '...') {
                            params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event)
                        }
                    }}
                    onCellContextMenu={(params: any) => {
                        params.api.contextMenuFactory.hideActiveMenu()
                    }}
                    treeData={true}
                    rowSelection={bulkActionHelper.isActive ? "multiple" : "single"}
                    components={components}
                    suppressCellFocus={true}
                    suppressMenuHide={true}
                    suppressContextMenu={true}
                    getRowId={(row: any) => row.data.id}
                    getDataPath={(data: any) => { return data.path; }}
                    modules={AllModules}
                    groupDefaultExpanded={-1}
                    maintainColumnOrder={true}
                    autoGroupColumnDef={{
                        hide: true,
                        minWidth: 0,
                        maxWidth: 0,
                        filter: false,
                        suppressAutoSize: true,
                        cellClassRules: cellClassRules
                    }}
                    defaultColDef={{
                        editable: false,
                        sortable: true,
                        filter: true,
                        resizable: true,
                        floatingFilter: true,
                    }}
                    context={{
                        isRowSelectable: bulkActionHelper.isRowSelectable,
                        selected: bulkActionHelper.selected,
                        bulkActionIsActive: bulkActionHelper.isActive,
                        onNewItemsAdded,
                    }}
                    rowMultiSelectWithClick={bulkActionHelper.isActive}
                    onRowClicked={handleRowSelected}
                    onCellFocused={handleCellFocused}
                    headerHeight={25}
                    onRowDragMove={onRowDragMove}
                    onRowDragLeave={onRowDragLeave}
                    onRowDragEnd={onRowDragEnd}
                    onExpandOrCollapseAll={props.onChangeFolderExpansionState}
                    onRowGroupOpened={props.onChangeFolderExpansionState}
                    onRowDataChanged={props.onChangeFolderExpansionState}
                >
                    <AgGridColumn
                        field="name"
                        rowGroup={true}
                        cellRenderer="agGroupCellRenderer"
                        minWidth={300}
                        initialWidth={405}
                        cellClass={getIndentClass}
                        initialSort='asc'
                        suppressCellFlash={true}
                        dndSource={(params: any) => {
                            return requestMode && (params.node.data?.hierarchyTypeId === HierarchyType.File.id);
                        }}
                        rowDrag={
                            (params: any) => {
                                const disallowedSources = [HierarchyType.Client.id, HierarchyType.Engagement.id, HierarchyType.SystemFolder.id]
                                return currentUser.isMossAdamsStaff && !requestMode && !disallowedSources.includes(params.node.data?.hierarchyTypeId);
                            }
                        }
                        hide={false}
                        lockVisible={true}
                        filter="agTextColumnFilter"
                        cellRendererParams={{
                            suppressCount: true,
                            innerRenderer: "treeCellRenderer",
                        }}
                        comparator={hierarchyNameSort}
                        cellClassRules={cellClassRules}
                    ></AgGridColumn>
                    <AgGridColumn
                        field="associatedTasks"
                        headerName="Req #"
                        minWidth={87}
                        initialWidth={87}
                        maxWidth={300}
                        cellRenderer="requestNumberCellRenderer"
                        comparator={associatedTasksComparator}
                        filter="agTextColumnFilter"
                        valueGetter={(params: ValueGetterParams) => {
                            const data: IContentHierarchy = params.data;
                            const val = data?.associatedTasks?.length
                                ? data?.associatedTasks.map(at => at.taskNumber).join(',')
                                : data?.hierarchyTypeDescription === HierarchyType.File.string ? 'Unfiled' : ''
                            return val;
                        }
                        }
                        cellClassRules={cellClassRules}
                    />
                    <AgGridColumn
                        field="isPrivate"
                        headerName="Private"
                        minWidth={60}
                        maxWidth={60}
                        headerComponent={() => <RestrictedIcon />}
                        cellRenderer="privateFileCellRenderer"
                        cellClassRules={cellClassRules}
                    />
                    <AgGridColumn
                        cellRenderer="uploadedByRenderer"
                        field="uploadedByUserName"
                        headerName="Uploaded By"
                        minWidth={110}
                        filter="agTextColumnFilter"
                        cellClassRules={cellClassRules}
                    />
                    <AgGridColumn
                        field="updatedDateTime"
                        headerName="Last Modified"
                        valueFormatter={(params: ValueGetterParams) => dateFormatter(params.data?.updatedDateTime)}
                        filter="agDateColumnFilter"
                        getQuickFilterText={(params: any) => {
                            return LocalDateTimeString(params.value as Date, false);
                        }}
                        minWidth={180}
                        filterParams={{
                            comparator: (filterLocalDateAtMidnight: any, cellValue: Date) => {
                                var cellDate = new Date(cellValue.getFullYear(), cellValue.getMonth(), cellValue.getDate())
                                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                                    return 0;
                                }
                                if (cellDate < filterLocalDateAtMidnight) {
                                    return -1;
                                }
                                if (cellDate > filterLocalDateAtMidnight) {
                                    return 1;
                                }
                                return 0;
                            },
                            inRangeInclusive: true,
                            defaultOption: 'inRange'
                        }}
                        cellClassRules={cellClassRules}
                    />
                    <AgGridColumn
                        field="fileSize"
                        headerName="Size"
                        cellClass={classes.rightAlignedGridCell}
                        minWidth={94}
                        maxWidth={100}
                        filter={false}
                        suppressFilterButton={true}
                        valueFormatter={(params: any) => {
                            return params.data?.hierarchyTypeDescription == HierarchyType.File.string ? Utils.getFileSize(params.data?.fileSize) : ''
                        }}
                        cellClassRules={cellClassRules}
                    />
                    {currentUser.isMossAdamsStaff ?
                        <AgGridColumn
                            field="expirationDate"
                            headerName="Expiration"
                            filter={ExpireDateFilter}
                            valueFormatter={(params: ValueGetterParams) => dateFormatter(params.data?.expirationDate)}
                            minWidth={180}
                            getQuickFilterText={(params: any) => {
                                return LocalDateTimeString(params.value, false);
                            }}
                            cellRenderer={"expirationDateCellRenderer"}
                            cellClassRules={cellClassRules}
                        />
                        : null
                    }
                    <AgGridColumn
                        headerName=""
                        field="..."
                        suppressMenu={true}
                        suppressColumnsToolPanel={true}
                        cellRenderer="actionsRenderer"
                        filter={false}
                        minWidth={40}
                        maxWidth={40}
                        pinned={'right'}
                        cellClassRules={cellClassRules}
                    />
                </AgGridReact>
            </div>
        </Paper>
    )
}
